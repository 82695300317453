import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import RouteManager from "../../managers/RouteManager";
import { TableManger, TABLES, TABLE_DISPLAY_NAME } from "../../managers/TableManager";
import { PayoutLogsColumnConfig } from "../../services/Config";
import PayoutLogsService from "../../services/PayoutLogsService";
import Utility, { removeDelayedLoader, showDelayedLoader } from "../../utils/Utility";
import DataGridHolderPayroll from "../common/DataGridHolderPayroll";

function PayoutLogs(props) {
    const history = useHistory();

    const [payouts, setPayouts] = useState(null);
    const [gridData, setGridData] = useState({
        columnData: [],
        rowData: [],
        originalData: [],
        filter: []
    });

    useEffect(() => {
        RouteManager.setPresenter({ props: { ...props, history: history } });

        fetchPayouts();
    }, []);

    useEffect(() => {
        if (!Utility.isEmptyObject(payouts)) {
            const rowData = PayoutLogsColumnConfig(payouts);
            // const columnConfig = parseVisibleColumnConfig(parseColumnConfig(TableManger.getTableVisibleColumns(TABLES.PEOPLE_EXPENSE_PAYOUT_LOGS), TABLES.PEOPLE_EXPENSE_PAYOUT_LOGS));
            const columnConfig = [
                {
                    key: "expenseId",
                    name: "Expense ID",
                    width: 150
                },
                {
                    key: "date",
                    name: "Date",
                    width: 150
                },
                {
                    key: "applicant",
                    name: "Applicant",
                    width: 150
                },
                {
                    key: "amount",
                    name: "Amount",
                    width: 150
                },
                {
                    key: "status",
                    name: "Status",
                    width: 150
                }
            ]

            const gridData = {
                columnData: columnConfig,
                rowData: rowData,
                filter: [],
                originalData: rowData
            };

            setGridData(gridData);
        }
    }, [payouts]);

    const fetchPayouts = () => {
        const timeout = showDelayedLoader();
        PayoutLogsService.getPayouts().then(
            (response) => {
                setPayouts(response);
                removeDelayedLoader(timeout);
            }, (error) => {
                setPayouts([]);
                removeDelayedLoader(timeout);
            }
        );
    }

    return (
        <Fragment>
            <div className="parent-width parent-height">
                <div className="column" style={{ height: "90%" }}>
                    <div className="flex-1 position-relative" style={{ minHeight: "200px" }}>
                        <DataGridHolderPayroll
                            tableName={TABLES.PEOPLE_EXPENSE_PAYOUT_LOGS}
                            displayTableName={TABLE_DISPLAY_NAME[TABLES.PEOPLE_EXPENSE_PAYOUT_LOGS]}
                            allowColumnEdit={false}
                            allowColumnSort={false}
                            allowRowEdit={true}
                            onRowOpenBtnHidden={true}
                            gridData={gridData}
                            onSelection={(selection) => {}}
                            onFilter={(dataList, queries) => {}}
                            addButtonConfig={[]}
                            addMenuButton={[]}
                            onPaginationClick={(requestedPageNumber) => {}}
                            onCancelContext={() => {}}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PayoutLogs;
