import { APP_NAME } from "./Constants";

export default class ApiConstants {
  static URL = {
    BASE: process.env.REACT_APP_BASE_URL,
    TABLE: {
      GET_ALL: `v1/crm/core/table`,
      GET: (tableId) => `v1/crm/core/table/${tableId}`,
      ADD_COLUMN: (tableId) => `v1/crm/core/table/${tableId}/column`,
      ADD_RECORD: (tableId) => `v1/crm/core/table/${tableId}/record`,
      GET_RECORD: (tableId) => `v1/crm/core/table/${tableId}/record`,
      GET_RECORD_BY_PAGE: (tableId) =>
        `v1/crm/core/table/${tableId}/paged-record`,
      UPDATE_RECORD: (recordId, tableId) =>
        `v1/crm/core/table/${tableId}/record/${recordId}`,
      GET_RECORD_BY_ID: (recordId, tableId) =>
        `v1/crm/core/table/${tableId}/record/${recordId}`,
      DELETE_RECORD: (recordId, tableId) =>
        `v1/crm/core/table/${tableId}/record/${recordId}`,
      DELETE_BULK_RECORD: (tableId) => `v1/crm/core/table/${tableId}/record`,
      DELETE_COLUMN: (columnId, tableId) =>
        `v1/crm/core/table/${tableId}/column/${columnId}`,
      UPDATE_COLUMN: (columnId, tableId) =>
        `v1/crm/core/table/${tableId}/column/${columnId}`,
      SHIFT_COLUMN: (tableId) => `v1/crm/core/table/${tableId}/save-col-order`,
      ADD_BULK_RECORD: (tableId) => `v1/crm/core/table/${tableId}/record-bulk`,
      UPDATE_BULK_RECORD: (tableId) =>
        `v1/crm/core/table/${tableId}/record-bulk`,
      ADD_TABLE: `v1/crm/core/table`,
    },
    SETTINGS: {
      USER: `v1/crm/core/settings/user`,
    },
    IAM: {
      REDIRECT: process.env.REACT_APP_LOGIN_URL,
      LOG_OUT: `v1/iam/auth/logout`,
      STATUS: `v1/iam/auth/sign-in/login/status`,
      SWITCH_TENANT: `v1/iam/users/web/switch-tenant`,
      TFA_STATUS: `v1/iam/auth/sign-in/web/mfa-status`,
      ENABLE_TFA: `v1/iam/auth/sign-in/web/mfa-enable`,
      DELETE_TFA: `v1/iam/auth/sign-in/web/mfa`,
      ADD_USER: `v1/iam/users/tenants/roles`,
      UPDATE_USER: `v1/users/update/info`,
      CHANGE_PASSWORD: `v1/iam/users/change-password`,
      CODE_TFA: `v1/iam/auth/sign-in/web/mfa`,
      RESEND_VERIFICATION_MAIL: `v1/iam/auth/email/regenerate`,
    },
    AUTH: {
      VERIFY_EMAIL: `email/verification`,
    },
    SUBENGINE: {
      SUBSCRIPTION_PEOPLE: `v1/subengine/subscription/people`,
      PLAN: "v1/subengine/subscription?Version=v2",
      START_TRAIL: "v1/subengine/subscription",
    },

    USER: {
      ROLES: `v1/users/roles`,
      DELETE_REASON: `v1/iam/users/account/`,
      DETAIL: `v1/users/users/count`,
      USER_INFO: `v1/users/userinfo`,
      USER_INFO_BY_ID: `v1/users/userinfo?query=iamUserId=`,
      TENANTS: `v1/users/tenants`,
      DUPLICATE_CONTACT: `v1/people/tenants/email/validate`,
      USERS: `v1/users`,
    },
    TENANT: {
      GET_DETAILS: `v1/tenants/details`,
      CLIENT_DETAILS: `v1/tenants/bookkeeper/client-tenants`,
      COMPANY_NAME: `v1/tenants/TENANT_ID/settings/name`,
      RESET_COMPANY: `v1/tenants/reset`,
      DELETE_COMPANY: `v1/tenants/`,
      PAYMENT: `v1/tenants/bookkeeper/payment`,
      ORG_WITHOUT_SETUP: `v1/tenants/**/organisationwithoutsetup`,
      TENANTS: `v1/users/tenants`,
      COMPONENT_GROUP: `v1/people/tenants/component-group`,
      ADD_ORG: `v1/tenants/organisation`,
      ERP_TENANT: `v1/tenants`,
    },
    PUBLIC_PAGE: {
      GET_JSON_DATA: (id) => `v1/crm/core/deskera/open/${id}`,
    },
    SMTP: {
      SETUP: `v1/crm/emails/campaign/setup`,
      GET_ALL_SETUPS: `v1/crm/emails/campaign/setup?app_name=${APP_NAME}`,
      SETUP_WITH_ID: (id) => `v1/crm/emails/campaign/setup/${id}`,
    },
    IMPORT_EXPORT: {
      UPLOAD_FILE: `v1/attachment/file-upload`,
      UPLOAD_RECORDS: `v1/exim/people3/import`,
      AUDIT_LOG: `v1/exim/audit`,
      EXPORT_FILE: (tableId) => `v1/crm/core/table/${tableId}/export`,
      UPLOAD_FILE_PUBLIC: `v1/attachment/file-upload/public`,
      DOWNLOAD_FILE: `v1/attachment/file-download`,
      EXPORT_PAYRUN: "v1/exim/payrun/export",
      PAYSTUB_DOWNLOAD: "v1/people-pay-run/payslips/paystub/",
      EXIM: "v1/exim",
      COMPLIANCE: (userId, form) =>
        `v1/people/compliance/export/${userId}/${form}`,
      COLUMN_MAPPING: "v1/exim/column-mapping",
      IMPORT: "v1/exim/import",
    },
    ATTACHMENT: {
      UPLOAD: `v1/attachment/upload`,
      GET_ALL: (entityId, entityName) =>
        `v1/attachment/getAttachments?entityId=${entityId}&entityName=${entityName}`,
      DOWNLOAD: (attachmentId) =>
        // `v1/attachment/download?attachmentId=${attachmentId}`,
        `v1/attachment/download?attachmentId=${attachmentId}&entityName=EXPENSE`,
      DELETE: (attachmentId) =>
        `v1/attachment/delete?attachmentId=${attachmentId}`,
      UPDATE: `v1/attachment/update`,
    },
    CUSTOM_DOMAIN: {
      DOMAIN_URL: `v2/crm/guide/custom-domain`,
    },
    PAYROLL: {
      GET_PAYROLL: `v1/people-pay-run/payruns`,
      GET_WORKWEEK: `v1/people/tenants/workweek`,
      GET_PAYSCHEDULELIST: `v1/people-pay-schedule/pay-schedules/tenant`,
      GET_USERS: `v1/people/users`,
      USER: "v1/people/users/login/info",
      PROCESS_PAYROLL: `v1/people-pay-run/payruns/status`,
      PAYSTUB: "/v1/people-pay-run/paystubs",
      REVERT: (payrunId) => `v1/people-pay-run/payruns/revert/${payrunId}`,
      FILTER_USER: "v1/people/users/filtered-users",
    },
    PEOPLE: {
      ATTENDANCE: `v1/people/attendance`,
      PROXY_ATTENDANCE: `v1/people/attendance/proxy`,
      SELF: `v1/people/attendance/self`,
      ATTENDANCE_SELF: (limit, sortOn, sortDir) =>
        `v1/people/attendance/self?limit=${limit}&sort=${sortOn}&sortDir=${sortDir}`,
      TENANT: "v1/people/tenants",
      CONFIG: "v1/people/tenants/configuration",
      USER: "v1/people/users/login/info",
      PAY_SCHEDULE: `v1/people-pay-schedule/pay-schedule`,
      DESIGNATION: "v1/people/users/designations",
      COMPONENT: "v1/people-component/",
      PAY_SCHEDULE_BASE: "v1/people-pay-schedule",
      BANK: "v1/people/tenants/banks",
      NOTIFICATION_SETTINGS: "v1/people/tenants/notification-settings",
    },
    CASHFREE: {
      LINK_ACCOUNT: `v1/people/payment/verification/link_account`,
      GET_ACCOUNTS: `v1/people/payment/verification/fetch_accounts`,
      UPDATE_ACCOUNT: `v1/people/payment/verification/update_account`,
      VERIFY_IFSC_ACCOUNT_NUMBER: `v1/people/payment/verification/bankDetails`,
      SYNC_BENEFICIARIES: `v1/people/users/sync-beneficiaries`,
      RETRY_PAYMENT: (payrunId) =>
        `v1/people-pay-run/payruns/process-payment/${payrunId}`,
    },
    EMPLOYEE: {
      DELETE_EMPLOYEE: `v1/people/users/delete/bulk`,
      GET_HOLIDAYS: `v1/people/tenants/holidays`,
      GET_EMPLOYEE_META: (countryCode) =>
        `v1/people/compliance/${countryCode}/employee-meta?isFromNewSetup=true`,
      SAVE_EMPLOYEE: `v1/people/users`,
      EMPLOYEE_SERVICE: "/v1/people-employees",
      GET_DETAILS: (userId) => `/v1/people/users/${userId}`,
      GET_TAX_DETAILS: (countryCode) =>
        `/v1/people/compliance/${countryCode}/tax-applicable`,
    },
    COMPLIANCE: {
      BASE: "v1/people/compliance",
      IN: {
        GET_IT_DECLARATION_CONFIG: "v1/people/tenants/configuration",
        GET_TAX_DECLARATION_ITEMS:
          "v1/people/compliance/IN/tax-declaration-items",
        GET_IT_DECLARATION: "v1/people-employees/in/tax-declaration-info",
        POST_IT_DECLARATION: "v1/people-employees/in/tax-declaration-info",
        PUT_IT_DECLARATION: "v1/people-employees/in/tax-declaration-info",
        GET_TAX_ESTIMATE: "v1/people/compliance/IN/tax-estimate",
        GET_TAX_ESI: "v1/people/compliance/IN/tax-esi-rate",
        GET_IN_TAX_DETAIL: "v1/people/tenants/IN/tax",
        TOGGLE_INTEGRATION_MAPPING:
          "v1/people/tenants/toggle-integration-mapping",
        GET_INTEGRATION_MAPPING: "v1/people/tenants/integration-mapping",
        GET_EXPENSE_AND_LIABILITY_ACCOUNTS:
          "v1/account?accountGroups=Expenses,Current Liabilities,Other Expenses&checkOpeningEditable=false&limit=500&page=0",
      },
      US: {
        SEARCH_ADDRESS: "v1/tax-calc/address/search",
        DOWNLOAD_US_STATE_FORM: (formType) =>
          `v1/people/compliance/us-forms/${formType}`,
      },
    },
    REPORT: {
      PAYROLL_LIABILITY_REPORT: "v1/people-pay-run/paystubs/report/liability",
      GET_FORMS_FIELDS: (country, state) =>
        `v1/people/compliance/${country}/state-taxes-meta/${state}`,
    },
    NOTIFICATION: {
      GET_ALL: "v1/crm/bell-notifications",
    },
    CUSTOM_FIELD: {
      GET: "v1/people/tenants/custom-fields",
    },
    GEO_LOCATION: {
      VIEW_LOCATION: (latitude, longitude) =>
        `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
    },
    DOCUMENT: {
      PRINT_INFO_TEMP: "v1/template/people-print-info/",
      PRINT_INFO: "v1/template/people-template-print-info",
      CHECK_PRINT_INFO: "v1/template/people-check-print-info",
      PRINT_INFO_CHECK: "v1/template/people-print-info/cheque",
      PRINT_DOC_BY_ID: (uid) =>
        `v1/template/print-info/print-design-doc-by-uid/${uid}`,
    },
    WORKFLOW: {
      GET_CONNECTIONS: `v1/workflow/connection`,
      CREATE_CONNECTION: `v1/workflow/connection-internal`,
      EMAIL_VERIFICATION: `v1/crm/campaign/email-verification`,
      FLOW_EXEC: `v1/workflow-engine-people/flow/exec`,
      EXPENSE_GROUP_ACTIVE: 'v1/workflow-engine-people/flow/expense-group/active'
    },
    CURRENCY: {
      GET_ENUM: `v1/multicurrency/getCurrencyEnum`,
    },
    EXPENSE: {
      CATEGORY: "v1/expenses/admin/categories",
      EXPENSES: "v1/expenses",
      EXPENSES_BREAKDOWN: "v1/expenses/breakdown",
      TAXES: "v1/taxes",
      UPLOAD: "v1/expenses/receipt/upload",
      IMAGE: "v1/expenses/process/image",
      GET_EXPENSES: "v1/expenses",
      PATCH_INTEGRATION_TOGGLE: (status) =>
        `v1/expenses/toggle-integration-mapping/${status}`,
      GET_INTEGRATION_MAPPING: `v1/expenses/integration-mapping`,
      POST_INTEGRATION_SETTINGS: ``,
      GROUP: `v1/expenses/group`,
      BULK_USER: `v1/expenses/bulkUser`,
      GET_USER_BY_GROUP_ID: (id) => `v1/expenses/group/${id}/users`,
      USER: `v1/expenses/user`,
      MOVE_USER: `v1/expenses/moveUser`,
      APPROVE_EXPENSE: "v1/expenses/update",
      GET_CATEGORY: "v1/expenses/category",
      SYNC_REVERT_JE: (action) => `v1/expenses/je/${action}`,
      CLAIMS_BY_TREND: `v1/expenses/trend`,
      GET_USER_ROLES: `v1/expenses/userroles`,
      STATUS: `v1/expenses/status`,
      GET_NO_GROUP_USERS: "v1/expenses/roles/users",
      MULTI_EXPENSE: "v1/expenses/multi",
      PAYPAL: {
          REDIRECT: "v1/expenses/paypal/redirecturl",
          CONNECT: "v1/expenses/paypal/connect",
          DISCONNECT: "v1/expenses/paypal/disconnect"
      },
      PAYOUTS: {
          GET_USERS: "v1/expenses/payouts/users",
          UPDATE_USERS: "v1/expenses/payouts",
          PROCESS_PAYOUT: "v1/expenses/payouts/process/",
          GET_PAYOUTS: "v1/expenses/payouts"
      }
    },
    LOCATION: {
      GET_LOCATION_LIST: `v1/dimension/location`,
      UPDATE_LOCATION: (id) => `v1/dimension/location/${id}`,
    },
    CHECKHQ: {
      GET_COMPONENT_LINK: "v1/people/tenants/check/component/urls",
      GET_COMPANY_AND_INDUSTRY_TYPE:
        "v1/people/tenants/business-classifications",
      SYNC_CHANGES: "v1/people/tenants/check-hq/sync",
    },
  };

  static ACCESS_TOKEN = null;
  static LOCALHOST_URL = "localhost:3000";

  static PRODUCT_URL_GO = process.env.REACT_APP_URL_GO;
  static PRODUCT_URL_ERP = process.env.REACT_APP_URL_ERP;
  static PRODUCT_URL_CRM = process.env.REACT_APP_URL_CRM;
  static PRODUCT_URL_PEOPLE = process.env.REACT_APP_URL_PEOPLE;
  static PRODUCT_URL_WEBSITE_BUILDER =
    process.env.REACT_APP_URL_WEBSITE_BUILDER;
  static PRODUCT_URL_SHOP = process.env.REACT_APP_URL_SHOP;
  static PRODUCT_URL_REPORT_BUILDER = process.env.REACT_APP_URL_REPORT_BUILDER;
  static PRODUCT_URL_DOCUMENT_BUILDER =
    process.env.REACT_APP_URL_DOCUMENT_BUILDER;
  static PRODUCT_URL_WORKFLOW_ENGINE =
    process.env.REACT_APP_URL_WORKFLOW_ENGINE;
  static PRODUCT_URL_ELEAVE = process.env.REACT_APP_URL_ELEAVE;
  static PRODUCT_URL_EXPENSE = process.env.REACT_APP_URL_EXPENSE;
  static AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;

  static CASHFREE_WHITELIST_IP = process.env.REACT_APP_URL_WHITELISTED_IP;
  static CASHFREE_WEBHOOKS = process.env.REACT_APP_URL_WEBHOOK_URL;
  static CASHFREE_WHITELIST_REDIRECT =
    process.env.REACT_APP_URL_WHITELIST_REDIRECT;
  static CASHFREE_WEBHOOKS_REDIRECT =
    process.env.REACT_APP_URL_WEBHOOKS_REDIRECT;

  static COMPLIANCE_COUNTRY = process.env.REACT_APP_COMPLIANCE_COUNTRY;
  static EMPLOYEE_LOGIN_REDIRECT =
    process.env.REACT_APP_EMPLOYEE_LOGIN_REDIRECT === "true";
  static SWITCH_NEW_WINDOW = process.env.REACT_APP_SWITCH_NEW_WINDOW === "true";
  static AUTOSAVE_INTERVAL = process.env.REACT_APP_AUTOSAVE_INTERVAL;
  static LOADING_MASK_DELAY = process.env.REACT_APP_LOADING_MASK_DELAY;

  static URL_MOBILE_IOS =
    "https://apps.apple.com/app/desk-business-and-accounting/id1463523833?ls=1";
  static URL_MOBILE_ANDROID =
    "https://play.google.com/store/apps/details?id=com.deskera.desk";

  //static FORM_EMBEDDED_URL = "https://js-bucketnew.s3.ap-southeast-1.amazonaws.com/embed.js"
  static FORM_SUBMISSION_URL =
    "https://js-bucketnew.s3.ap-southeast-1.amazonaws.com/FormSubmission.js";
  static LOAD_SCRIPTS_URL =
    "https://js-bucketnew.s3.ap-southeast-1.amazonaws.com/LoadScript.js";
  static MULTI_PAYMENT = process.env.REACT_APP_URL_MULTI_PAYMENT;
  static PAYPAL_PAYOUT_ENABLED = process.env.REACT_APP_PAYPAL_PAYOUT_ENABLED === "true";
  static CHECKHQ_PAYOUT_ENABLED = process.env.REACT_APP_CHECKHQ_PAYOUT_ENABLED === "true";
  static PRODUCT_URL_PEOPLE_PLUS = process.env.REACT_APP_URL_PEOPLE_PLUS;
}
