import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    DKLabel,
    DKButton,
    DKCalendar,
    showToast,
    TOAST_TYPE,
    DKIcon,
    DKIcons,
    DKInput,
    removeLoader,
    showLoader,
    showAlert,
    INPUT_VIEW_DIRECTION
} from "deskera-ui-library";
import AppManager from "../../managers/AppManager";
import { ADMIN } from "../../managers/common/RouteAuth";
import { useDispatch, useSelector } from "react-redux";
import RouteManager from "../../managers/RouteManager";
import SegmentListContainer from "../list/SegmentListContainer";
import calender from '../../assets/icons/white/date_range.svg';
import Utility, { deepClone, removeDelayedLoader, showDelayedLoader } from "../../utils/Utility";
import moment from 'moment';
import { FILE_TYPE, FORMAT } from "../../utils/Constants";
import DataGridHolderPayroll from "../common/DataGridHolderPayroll";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManager";
import { fetchCategories, fetchExpenseClaims, fetchTaxes, selectCategories, selectExpenses, selectTaxes } from "../../redux/slices/ExpenseClaimListSlice";
import { ExpenseClaimsColumnConfig, parseColumnConfig, parseVisibleColumnConfig } from "../../services/Config";
import ExpenseClaimListService, { EXPENSE_CONSTANTS } from "../../services/ExpenseClaimListService";
import Popup from "../common/Popup";
import ClaimForm from "./NewClaim";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import _ from 'lodash';
import { TENANT, TenantService } from "../../services/TenantMangerService";
import UserManager from "../../managers/UserManager";
import PopupList from "../list/PopupList";
import MultipleExpensePopup from "./MultipleExpensePopup";
import ExpenseService from "../../services/ExpenseService";
import i18next, { t } from "i18next";
import { EXPENSE_CLAIM_STATUS } from "./ClaimsConstants";
import { getFileNameFromContentDisposition } from "../../managers/common/CommonUtil";
import { saveAs } from 'file-saver';
import { isMobileWebView } from "../../utils/ViewportSizeUtils";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
var searchTimeout;

function ExpenseClaims(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const [tabs, setTabs] = useState([]);
    const [selectedTabId, setSelectedTabId] = useState("");
    const [showClaimPopup, setClaimPopup] = useState({
        isVisible: false,
        claimId: null,
        isDuplicate: false
    });
    const [showMultiClaimPopup,setMultiClaimPopup] = useState({
        isVisible: false
    })
    const [showEditGroup, setShowEditGroup] = useState({
        isVisible:false,
        title: i18next.t("GROUP_NAME_TITLE"),
        canValidate: false,
        value:'',
        data:null
    })
    const list = [
        {
            id: "single",
            icon:"",
            title: i18next.t("SINGLE_CLAIM")
        },
        {
            id: "multiple",
            icon:"",
            title:i18next.t("MULTI_CLAIM")
        }
    ]
    const initialClaimPeriod = {
        toggle: false,
        startDate: moment(moment().format()).startOf('month'),
        endDate: moment(moment().format()).endOf('month'),
        defaultDate: false
    };
    const [claimPeriod, setClaimPeriod] = useState(initialClaimPeriod);

    const initialExpenseClaims = {
        columnData: [],
        rowData: [],
        originalData: [],
        filter: []
    };
    const [expenseClaims, setExpenseClaims] = useState(initialExpenseClaims);
    const [filters, setFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [createNewPopup, setCreateNewPopup] = useState(false)
    const expenses = useSelector(selectExpenses);
    const categories = useSelector(selectCategories);
    const taxes = useSelector(selectTaxes);
    const [reload, setReload] = useState(false);

    const [isTransactionDone, setTransationDone] = useState(false);
    const [showTransactionHistory, setTransactionHistory] = useState([]);
    const [applicantName, setApplicantName] = useState("");
    const [exporting, setExporting] = useState(false);
    const [searchText, setSearchText] = useState(null);

    const onlyMultipleClaim = true;
    const [isAdminOrManager, setIsAdminOrManager] = useState(false);

    useEffect(() => {
        RouteManager.setPresenter({ props: { ...props, history: history } });
        AppManager.scrollToTop();

        if (props.accessedBy === ADMIN) {
            setTabs([
                { id: "", name: "All Claims" },
                { id: "DRAFT", name: "Drafts" },
                { id: "PENDING", name: "To Review" },
                { id: "APPROVED", name: "Approved" },
            ]);
        } else {
            setTabs([
                { id: "", name: "All Claims" },
                { id: "DRAFT", name: "Drafts" },
                { id: "PENDING", name: "Submitted" },
                { id: "APPROVED", name: "Approved" },
            ]);
        }

        if (ExpenseClaimListService.activeTab) {
            setSelectedTabId(ExpenseClaimListService.activeTab);
            ExpenseClaimListService.activeTab = "";
        }

        dispatch(fetchCategories());
        dispatch(fetchTaxes());

        if(!showClaimPopup.isVisible||!showMultiClaimPopup.isVisible||!showEditGroup.isVisible) {
            fetchExpenses(getAPIConfig(claimPeriod.startDate, claimPeriod.endDate, currentPage, selectedTabId));
        }
        setIsAdminOrManager(TenantService.get(TENANT.EXPENSE_ROLE) === "ADMIN" || TenantService.get(TENANT.EXPENSE_ROLE) === "MANAGER");

    }, [showClaimPopup,showMultiClaimPopup, showEditGroup, reload]);

    useEffect(() => {
        if (!Utility.isEmptyObject(expenses)) {
            const { content, number, totalPages } = expenses;
            const rowData = ExpenseClaimsColumnConfig(content, onContextMenuAction, isAdminOrManager, UserManager.getUserDetails().id);
            const columnConfig = parseVisibleColumnConfig(parseColumnConfig(TableManger.getTableVisibleColumns(TABLES.PEOPLE_EXPENSE_CLAIMS), TABLES.PEOPLE_EXPENSE_CLAIMS, { categories: categories }));

            const claimsData = {
                columnData: deepClone(columnConfig),
                rowData: rowData,
                filter: [],
                originalData: rowData
            };
            setExpenseClaims(claimsData);
            setCurrentPage(number + 1);
            setTotalPageCount(totalPages);
        }
    }, [expenses]);

    useEffect(() => {
        if (exporting) {
            exportExpenseClaims();
        }
    }, [exporting]);

    const fetchExpenses = async (apiConfig) => {
        const timeout = showDelayedLoader();
        if (apiConfig) {
            ExpenseClaimListService.apiConfig = apiConfig;
        }
        await dispatch(fetchExpenseClaims());
        removeDelayedLoader(timeout);
    }

    const onTabChange = (tab) => {
        setSelectedTabId(tab.id);
        fetchExpenses(getAPIConfig(claimPeriod.startDate, claimPeriod.endDate, currentPage, tab.id));
    };

    const getAPIConfig = (startDate, endDate, currentPage, status, category, groupName, searchText) => {
        const params = {
            view: TenantService.get(TENANT.EXPENSE_ROLE),
            page: currentPage - 1,
            limit: 20
        };

        if (searchText) {
            params["search"] = searchText;
        }

        return {
            params: params,
            queryParams: getQueryParams(startDate, endDate, status, category, groupName)
        }
    }

    const getFilterCondition = (condition) => {
        switch (condition) {
            case "c":
                return "~";
            default:
                return "=";
        }
    }

    const getQueryParams = (startDate, endDate, status, category,   groupName) => {
        const queryParams = {
            "spendDate>=": moment(startDate).format(FORMAT.YYYYMMDD),
            "spendDate<=": moment(endDate).format(FORMAT.YYYYMMDD)
        }

        if (status) {
            queryParams["status="] = status;
        }

        if (category) {
            queryParams["categoryId="] = category;
        }

        if (groupName) {
            queryParams[`expenseGroup${getFilterCondition(groupName.condition)}`] = groupName.value;
        }

        return queryParams;
    }

    const getClaimPeriodText = (startDate, endDate) => {
        if (startDate && endDate) {
            return `${Utility.formatDate(moment(startDate).format(FORMAT.DDMMYYYY), FORMAT.DDMMYYYY)} - ${Utility.formatDate(moment(endDate).format(FORMAT.DDMMYYYY), FORMAT.DDMMYYYY)}`;
        }

        return `${Utility.formatDate(moment().startOf('month').format(FORMAT.DDMMYYYY), FORMAT.DDMMYYYY)} - ${Utility.formatDate(moment().endOf('month').format(FORMAT.DDMMYYYY), FORMAT.DDMMYYYY)}`;
    }

    const onNewClaim = () => {
        setClaimPopup({ isVisible: true, claimId: null })
    }

    const onContextMenuAction = (action, data) => {
        switch (action) {
            case EXPENSE_CONSTANTS.APPROVE:
            case EXPENSE_CONSTANTS.REJECT:
                approveOrRejectClaims(action, data?.rowData?.id, false);
                break;
            case EXPENSE_CONSTANTS.DELETE:
                deleteExpense(data?.rowData?.id);
                break;
            case EXPENSE_CONSTANTS.PRINT:
                printExpenses([data?.rowData]);
                break;
            case EXPENSE_CONSTANTS.SUBMITTED:
                submitExpense(data?.rowData?.data);
                break;
            case EXPENSE_CONSTANTS.DUPLICATE:
                duplicateExpense(data?.rowData?.data);
                break;
            case EXPENSE_CONSTANTS.EDIT_GROUP_NAME:
                setShowEditGroup({
                    ...showEditGroup,
                    data:data?.rowData?.data,
                    value:data?.rowData?.data?.expenseGroup,
                    isVisible:true
                })
                break;
            case EXPENSE_CONSTANTS.EXPENSE_PAYPAL_PAY:
                payWithPaypal(data?.rowData?.data);
                break;
            case EXPENSE_CONSTANTS.EXPENSE_CHECK_PAY:
                payRespectiveExpense(data?.rowData?.data);
                break;
        }
    }

    const payRespectiveExpense = (data) => {
        showLoader("Paying... Please wait");
        const expenseAmount = data.totalAmount;
        const expenseId = data.code;
        const employeeName = data.userName;

        const params = {
          expenseId: expenseId,
          expenseAmount: expenseAmount,
        };

        ExpenseClaimListService.PayCLaims(params, params).then(
          (response) => {
            removeLoader();
            const deleteConfirm = [
              {
                title: "Reject",
                className: "bg-gray1 border-m",
              },
              {
                title: "Approve",
                className: "bg-app text-white ml-r",
                onClick: () => approvePayment(employeeName),
              },
            ];
            showAlert(
              "Payment Approval",
              "Please approve current payout?",
              deleteConfirm
            );
          },
          (error) => {
            removeLoader();
            showToast("Something went wrong", TOAST_TYPE.FAILURE);
          }
        );
    }

    const approvePayment = (name) => {
        showLoader("Approval is in progress... Please wait")
        ExpenseClaimListService.ApprovePayment().then((response) => {
            removeLoader();
            showToast("Payment approved successfully", TOAST_TYPE.SUCCESS);
            getPaystubList(name)
        }, (error) => {
            removeLoader();
            showToast("Something went wrong", TOAST_TYPE.FAILURE)
        })
    }

    const getPaystubList = (name) => {
        showLoader("Fetching Details... Please wait");
        ExpenseClaimListService.getExpensePaystubList().then(
          (response) => {
            setApplicantName(name)
            setTransationDone(true);
            setTransactionHistory(response?.paystubObject);
            removeLoader();
          },
          (error) => {
              showToast("Something went wrong", TOAST_TYPE.FAILURE);
          }
        );
    }

    const approveOrRejectClaims = (view, id, isBulk) => {
        const params = {
            view: view
        }

        let payload;
        if (isBulk) {
            payload = selectedRows;
        } else {
            payload = [id];
        }

        ExpenseClaimListService.approveOrRejectClaims(payload, params).then(
            (response) => {
                showToast(view === EXPENSE_CONSTANTS.APPROVE ? i18next.t("EXPENSE_APPROVE") : i18next.t("EXPENSE_REJECT") , TOAST_TYPE.SUCCESS);
                fetchExpenses();
            }, (error) => {
                showToast(view === EXPENSE_CONSTANTS.APPROVE ? i18next.t("CANT_EXPENSE_APPROVE") : i18next.t("CANT_EXPENSE_REJECT"), TOAST_TYPE.FAILURE);
            }
        );
    }
    const multipleAction = (data,action) => {
        let isPending = -1 // for now set default to -1
        if(isPending == -1) {
            let ids = data.map(r=>r.id)
            let params = {
                view: action

            }
            ExpenseClaimListService.approveOrRejectClaims(ids, params).then(
                (response) => {
                    showToast(params.view === EXPENSE_CONSTANTS.APPROVE ?  i18next.t("EXPENSE_APPROVE") : (params.view === EXPENSE_CONSTANTS.PENDING ? i18next.t("EXPENSE_SUBMIT") :i18next.t("EXPENSE_REJECT")) , TOAST_TYPE.SUCCESS);
                    fetchExpenses();
                }, (error) => {
                    showToast(params.view === EXPENSE_CONSTANTS.APPROVE ? i18next.t("CANT_EXPENSE_APPROVE") : (params.view === EXPENSE_CONSTANTS.PENDING ? i18next.t("CANT_EXPENSE_SUBMIT") :i18next.t("CANT_EXPENSE_REJECT")), TOAST_TYPE.FAILURE);
                }
            );
        } else {
            showAlert(
                i18next.t("NOTE"),
                `${i18next.t("PENDING_CLAIM_ONLY")} ${(action === EXPENSE_CONSTANTS.APPROVE) ? `${i18next.t("APPROVED")}` : `${i18next.t("REJECTED")} `} `)
        }

    }
    const deleteExpense = (id) => {
        if (id) {
            ExpenseClaimListService.deleteExpense(id).then(
                (response) => {
                    showToast("Expense has been deleted successfully.", TOAST_TYPE.SUCCESS);
                    fetchExpenses();
                }, (error) => {
                    showToast("Couldn't delete expense, Please try again.", TOAST_TYPE.FAILURE);
                }
            );
        }
    }

    const printExpenses = (expenses) => {
        if (Array.isArray(expenses)) {
            expenses = expenses.map((expense) => expense.data);
            pdfMake.createPdf(getTemplate(expenses)).download("Expense Claims:" + Utility.formatDate(moment().format(FORMAT.DDMMYYYY), FORMAT.DDMMYYYY));
        } else {
            showToast("Invalid selection", TOAST_TYPE.FAILURE);
        }
    }

    const getTemplate = (expenses) => {
        const tableHeader = [
            {
                text: 'Expense ID',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Status',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Date',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Applicant',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Merchant',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Total Amount',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Tax Code',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Tax Amount',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Category',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Expense Group',
                style: 'tableHeader',
                alignment: 'center'
            },
            {
                text: 'Description',
                style: 'tableHeader',
                alignment: 'center'
            }
        ];
        const tableBody = [tableHeader];
        expenses.forEach(expense => {
            const tax = taxes.find(tax => tax.code === expense.taxCode);
            const expenseRow = [
                expense.code,
                expense.status,
                Utility.formatDate(expense.expenseDate, FORMAT.YYYYMMDD),
                _.get(expense, "userName", ""),
                expense.merchant,
                expense.currency + ' ' + expense.totalAmount,
                _.get(tax, "name", "-"),
                _.get(expense, "taxAmount", "-"),
                _.get(expense, "category", "-"),
                _.get(expense, "groupName", "-"),
                _.get(expense, "otherDetails", "-")
            ]
            tableBody.push(expenseRow);
        });
        return {
            pageSize: "A4",
            pageOrientation: 'landscape',
            pageMargins: [40, 40],
            content: [
                {
                    columns: [
                        {
                            stack: [{ text: TenantService.get(TENANT.ORGANIZATION_NAME), style: "companyName" }],
                            width: "35%"
                        }
                    ]
                },
                {
                    stack: [
                        {
                            text: 'Expense Claim',
                            style: "headingStyle1"
                        }
                    ],
                    margin: [1, 40]
                },
                {
                    widths: [10, "*", "*", 10],
                    table: {
                        body: tableBody
                    },
                }
            ],
            styles: {
                companyName: {
                    fontSize: 20,
                    bold: true,
                    alignment: "left"
                },
                headingStyle1: {
                    fontSize: 20,
                    bold: true,
                    alignment: "center"
                },
                headingStyle2: {
                    fontSize: 16,
                    alignment: "center"
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            },
            defaultStyle: {}
        }
    }

    const submitExpense = (data) => {
        if (data) {
            const payload = {
                ...data,
                status: EXPENSE_CONSTANTS.PENDING
            }
            ExpenseClaimListService.submitExpense(data.id, payload).then(
                (response) => {
                    showToast("Expense has been submitted successfully.", TOAST_TYPE.SUCCESS);
                    fetchExpenses();
                }, (error) => {
                    showToast("Couldn't submit expense, Please try again.", TOAST_TYPE.FAILURE);
                }
            );
        }
    }

    const duplicateExpense = (data) => {
        setClaimPopup({ isVisible: true, claimId: data?.id, isDuplicate: true });
    }

    const onRowEdit = (data) => {
        setClaimPopup({ isVisible: true, claimId: data?.rowData?.data?.id })
    }

    const onRowUpdate = (data) => {
        if(data.columnKey == COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP) {
            onGroupNameEdit(data.rowData)
        }
    }

    const onGroupNameEdit = (editGroupData) => {
        if (editGroupData.data) {
            showLoader();
            const obj = {
                ...editGroupData.data,
                deletedAttachmentIds: [],
                attachmentIds: [],
                attachments: [],
                expenseGroup: editGroupData.expenseGroup
            }
            ExpenseService.saveExpenses(obj, editGroupData.data.id).then(res => {
                removeLoader();
                showToast(i18next.t("GROUP_NAME"), TOAST_TYPE.SUCCESS);
            }, (error) => {
                removeLoader();
                if (showEditGroup.data['status'] === EXPENSE_CLAIM_STATUS.DRAFT) {
                    showToast(i18next.t("ERROR_EXPENSE_DRAFT"), TOAST_TYPE.FAILURE);
                }
            });
        }
    }

    const onFilter = (dataList, queries) => {
        setFilters(queries);

        const filter = {
            categoryId: null,
            expenseGroup: null
        };

        if (queries?.length > 0) {
            queries.forEach((query) => {
                const column = TableManger.getColumnById(TABLES.PEOPLE_EXPENSE_CLAIMS, query.key);
                switch (column?.columnCode) {
                    case COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.CATEGORY:
                        filter.categoryId = query.value[0];
                        break;
                    case COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP:
                        filter.expenseGroup = query;
                        break;
                }
            });
        }

        const config = getAPIConfig(claimPeriod.startDate, claimPeriod.endDate, currentPage, selectedTabId, filter.categoryId, filter.expenseGroup);
        fetchExpenses(config);
    }

    const onGroupNameChange = () => {
        if(!Utility.isEmpty(showEditGroup.value)){
            if (showEditGroup.data) {
                showLoader();
                let obj = {
                    ...showEditGroup.data,
                    deletedAttachmentIds:[],
                    attachmentIds: [],
                    attachments: [],
                    expenseGroup: showEditGroup.value
                }
                ExpenseService.saveExpenses(obj, showEditGroup.data.id).then(res => {
                    removeLoader();

                        showToast(i18next.t("GROUP_NAME"), TOAST_TYPE.SUCCESS);

                        setShowEditGroup({ ...showEditGroup, canValidate: false, data:null,value:'',isVisible:false })
                }, error => {
                    removeLoader();
                    if (showEditGroup.data['status'] === EXPENSE_CLAIM_STATUS.DRAFT) {
                        showToast(i18next.t("ERROR_EXPENSE_DRAFT"), TOAST_TYPE.FAILURE);
                    }
                })
            }
        } else {
            setShowEditGroup({ ...showEditGroup, canValidate: true })

        }

    }
    const gridAddRowButton = () => {
        return (
            <>
                {!onlyMultipleClaim && <div className="d-flex">
                    <DKLabel text={i18next.t("CREATE_NEW")} />
                    <DKIcon className="ic-s ml-r" src={DKIcons.white.ic_arrow_down2} />
                </div>}
                {onlyMultipleClaim && <div className="d-flex">
                    <DKLabel text={`+ ${i18next.t("NEW_CLAIM")} `} />
                </div>}
            </>
        )
    }
    const onListClick=(selectedOption)=>{
        switch(selectedOption.id) {
            case "single":
                onNewClaim()
                break;
            case "multiple":
                setMultiClaimPopup({isVisible:true})
                break;
        }
    }
    const onContextCancel = () => {
        setReload(!reload)
    }

    const payWithPaypal = (rowData) => {
        if (Utility.isEmpty(rowData)) {
            return;
        }

        showLoader("Payout in progress... Please wait");
        ExpenseClaimListService.payWithPaypal(rowData.id).then(
            (response) => {
                fetchExpenses();
                showToast("Expense paid successfully", TOAST_TYPE.SUCCESS);
                removeLoader();
            }, (error) => {
                showToast("Something went wrong", TOAST_TYPE.FAILURE)
                removeLoader();
            }
        );
    }

    const getExportButtonTitle = () => {
        if (exporting) {
            return `Exporting...`;
        } else {
            return `Export Expense Claims`;
        }
    };

    const exportExpenseClaims = () => {
        let categoryId;
        let expenseGroup;

        if (Array.isArray(filters) && filters.length > 0) {
            filters.forEach((query) => {
                const column = TableManger.getColumnById(TABLES.PEOPLE_EXPENSE_CLAIMS, query.key);
                switch (column?.columnCode) {
                    case COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.CATEGORY:
                        categoryId = query.value[0];
                        break;
                    case COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP:
                        expenseGroup = query.value;
                        break;
                }
            });
        }

        const params = {
            view: TenantService.get(TENANT.EXPENSE_ROLE),
            fileFormat: FILE_TYPE.XLS.toUpperCase()
        };

        if (searchText) {
            params["search"] = searchText;
        }

        const config = {
            params: params,
            queryParams: getQueryParams(claimPeriod.startDate, claimPeriod.endDate, selectedTabId, categoryId, expenseGroup)
        };

        ExpenseClaimListService.exportExpenseClaims(config).then(
            (response) => {
                const fileName = getFileNameFromContentDisposition(response.headers['content-disposition']);
                saveAs(response.data, fileName);
                setExporting(false);
            }, (error) => {
                showToast("Internal Server Error", TOAST_TYPE.FAILURE);
                setExporting(false);
            }
        );
    }

    const onSearch = (searchText) => {
        setSearchText(searchText);

        let categoryId;
        let expenseGroup;

        if (Array.isArray(filters) && filters.length > 0) {
            filters.forEach((query) => {
                const column = TableManger.getColumnById(TABLES.PEOPLE_EXPENSE_CLAIMS, query.key);
                switch (column?.columnCode) {
                    case COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.CATEGORY:
                        categoryId = query.value[0];
                        break;
                    case COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP:
                        expenseGroup = query.value;
                        break;
                }
            });
        }

        const config = getAPIConfig(claimPeriod.startDate, claimPeriod.endDate, currentPage, selectedTabId, categoryId, expenseGroup, searchText);
        fetchExpenses(config);
    };

    return (
      <>
        {showClaimPopup.isVisible && (
          <ClaimForm
            {...props}
            claimId={showClaimPopup.claimId}
            isDuplicate={showClaimPopup.isDuplicate}
            onClose={() => {
              setClaimPopup({ isVisible: false, claimId: null });
            }}
          />
        )}
        {showMultiClaimPopup.isVisible && (
          <MultipleExpensePopup
            {...props}
            onClose={() => {
              setMultiClaimPopup({ isVisible: false });
            }}
            maxWidth={1020}
          />
        )}
        {showEditGroup.isVisible && (
          <Popup>
            <>
              <div className="row justify-content-between">
                <DKLabel
                  className="fw-m fs-l"
                  text={i18next.t("EDIT_GROUP_NAME")}
                />
                <div className="d-flex">
                  <DKButton
                    title={i18next.t("CANCEL")}
                    className="border-m bg-gray1"
                    onClick={() =>
                      setShowEditGroup({
                        ...showEditGroup,
                        canValidate: false,
                        value: "",
                        data: null,
                        isVisible: false,
                      })
                    }
                  />
                  <DKButton
                    title={i18next.t("SAVE")}
                    className="bg-app text-white ml-m"
                    onClick={() => onGroupNameChange()}
                  />
                </div>
              </div>

              <DKInput
                className="mt-l"
                title={showEditGroup.title}
                placeholder={showEditGroup.title}
                value={showEditGroup.value}
                direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
                onChange={(data) => {
                  setShowEditGroup({
                    ...showEditGroup,
                    value: data.trim(),
                  });
                }}
                canValidate={showEditGroup.canValidate}
                required={true}
              />
            </>
          </Popup>
        )}
        <div className="parent-width parent-height">
                <div className="row">
                    <div className="column bg-deskera-secondary p-r border-radius-m border-m parent-width banner-holder">
                        <DKLabel text='<span style="font-size:20px">🙋‍♂️</span> Need help getting started? Learn more about managing expenses.' />
                        <div className="row mt-r ml-l">
                            <DKButton
                                className="border-m bg-white"
                                title="Read"
                                onClick={AppManager.showComingSoonWarning}
                            />
                            <DKButton
                                className="border-m ml-r bg-white"
                                title="Watch"
                                onClick={AppManager.showComingSoonWarning}
                            />
                        </div>
                    </div>
                    <div className="column bg-deskera-secondary p-r border-radius-m border-m parent-width ml-l banner-holder">
                        <DKLabel text='<span style="font-size:20px">💼</span> Manage your Expenses.' />
                        <div className="row mt-r ml-l">
                            {/* <DKButton
                                title="Import Expense Claims"
                                icon={DKIcons.ic_import}
                                className="border-m bg-white"
                                onClick={() => { }}
                            /> */}
                            <div className="row position-relative ml-r">
                                {!isMobileWebView() &&
                                    <DKButton
                                        title={getExportButtonTitle()}
                                        icon={DKIcons.ic_export}
                                        className="border-m bg-white"
                                        onClick={() => {
                                            setExporting(true);
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            <div>
                <SegmentListContainer
                    data={tabs}
                    selectedId={selectedTabId}
                    onChange={onTabChange}
                    className="d-flex justify-content-end"
                    maxWidth={React.createRef().current?.innerWidth}
                    isAddEnabled={false}
                />
            </div>
          <DKLabel text={"Expense Claims"} className="fw-m fs-l mb-s" />
          <div className="row" style={{ width: "193px" }}>
            <div className="column" style={{ zIndex: 99 }}>
              <DKButton
                className="bg-app text-white"
                title={getClaimPeriodText(
                  claimPeriod.startDate,
                  claimPeriod.endDate
                )}
                icon={calender}
                onClick={(value) => {
                  setClaimPeriod({
                    toggle: claimPeriod.toggle ? false : true,
                    startDate: claimPeriod.startDate,
                    endDate: claimPeriod.endDate,
                    defaultDate: true,
                  });
                }}
              />
              {claimPeriod.toggle && (
                <DKCalendar
                  className="position-absolute border-m shadow-m bg-white z-index-1 mt-s"
                  isDateRange={true}
                  startDate={
                    claimPeriod.startDate
                      ? new Date(claimPeriod.startDate)
                      : new Date()
                  }
                  endDate={
                    claimPeriod.endDate
                      ? new Date(claimPeriod.endDate)
                      : new Date()
                  }
                  onSelectDate={(startDate, endDate) => {
                    if (startDate && endDate) {
                      setClaimPeriod({
                        toggle: false,
                        startDate: startDate,
                        endDate: endDate,
                        defaultDate: false,
                      });
                      fetchExpenses(
                        getAPIConfig(
                          startDate,
                          endDate,
                          currentPage,
                          selectedTabId
                        )
                      );
                    }
                  }}
                  onClose={() => {
                    setClaimPeriod({
                      toggle: false,
                      startDate: claimPeriod.startDate,
                      endDate: claimPeriod.endDate,
                      defaultDate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <div className="column" style={{ marginTop: "-41px", height: "90%" }}>
            <div
              className="flex-1 position-relative"
              style={{ minHeight: "200px" }}
            >
              {createNewPopup && (
                <PopupList
                  list={list}
                  listTitle={i18next.t("CREATE_NEW")}
                  onClick={(obj) => {
                    onListClick(obj);
                  }}
                  onClose={() => {
                    setCreateNewPopup(false);
                  }}
                />
              )}
              <DataGridHolderPayroll
                tableName={TABLES.PEOPLE_EXPENSE_CLAIMS}
                allowColumnEdit={false}
                allowColumnDelete={false}
                allowColumnShift={false}
                allowSearch={true}
                allowFilter={true}
                allowColumnAdd={false}
                allowRowAdd={false}
                allowColumnSort={false}
                refresh={false}
                allowDataExport={false}
                allowRowEdit={true}
                showContextEdit={false}
                isActionEnabled={true}
                contextMenu={true}
                isMenuVisible={true}
                showContextMenu={true}
                gridData={expenseClaims}
                filter={filters}
                currentPage={currentPage}
                totalPageCount={totalPageCount}
                onSelection={(selection) => {
                  // setSelectedRows(selection); //  Note: will use in future
                }}
                onFilter={(dataList, queries) => {
                  onFilter(dataList, queries);
                }}
                onRowEdit={(data) => {
                  onRowEdit(data);
                }}
                onRowUpdate={(data) => {
                  onRowUpdate(data);
                }}
                onSearch={(searchText) => {
                    clearTimeout(searchTimeout);
                    searchTimeout = setTimeout(() => {
                        onSearch(searchText);
                    }, 500);
                }}
                addButtonConfig={[
                  {
                    title: gridAddRowButton(),
                    className: "bg-app text-white ml-s",
                    onClick: () => {
                      if (onlyMultipleClaim) {
                        setMultiClaimPopup({ isVisible: true });
                      } else {
                        setCreateNewPopup(!createNewPopup);
                      }
                    },
                  },
                ]}
                addMenuButton={[
                  {
                    title: i18next.t("APPROVE"),
                    className: "text-green bg-gray1 border-m mr-r ",
                    hide: !isAdminOrManager,
                    condition: {
                        columnCode: 'statusValue',
                        value: EXPENSE_CONSTANTS.PENDING,
                        keyMatch: 'isMultilevelApproval'
                    },
                    onClick: (data, value) => {
                      multipleAction(data, EXPENSE_CONSTANTS.APPROVE);
                    },
                  },
                  {
                    title: i18next.t("REJECT"),
                    className: "text-red bg-gray1 border-m mr-r ",
                    hide: !isAdminOrManager,
                    condition: {
                        columnCode: 'statusValue',
                        value: EXPENSE_CONSTANTS.PENDING,
                        keyMatch: 'isMultilevelApproval'
                    },
                    onClick: (data, value) => {
                      multipleAction(data, EXPENSE_CONSTANTS.REJECT);
                    },
                  },
                  {
                    title: i18next.t("SUBMIT"),
                    className: "text-app bg-gray1 border-m mr-r ",
                    hide: false,
                    condition: {
                      columnCode: COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.STATUS,
                      value: EXPENSE_CONSTANTS.DRAFT,
                    },
                    onClick: (data, value) => {
                      multipleAction(data, EXPENSE_CONSTANTS.PENDING);
                    },
                  },
                ]}
                onPaginationClick={(requestedPageNumber) => {
                  const config = getAPIConfig(
                    claimPeriod.startDate,
                    claimPeriod.endDate,
                    requestedPageNumber,
                    selectedTabId
                  );
                  fetchExpenses(config);
                }}
                onCancelContext={() => {
                  onContextCancel();
                }}
                hideTableName={{
                  hidden: true,
                  width: 197,
                }}
              />
            </div>
          </div>
        </div>
        {showTransactionHistory && isTransactionDone && (
          <div className="transparent-background">
            <div
              className="popup-window"
              style={{ maxWidth: "50%", height: "70%" }}
            >
              <div className="row-reverse mb-s">
                <DKButton
                  title={"Cancel"}
                  className="g-gray1 border-m"
                  onClick={(event) => {
                    setTransationDone(false);
                  }}
                />
              </div>
              <div className="row">
                <table>
                  <tr>
                    <td>SrNo</td>
                    <td>Employee Name</td>
                    <td>Expense Amount</td>
                    <td>Paydate</td>
                    <td>Status</td>
                  </tr>
                  {showTransactionHistory &&
                    showTransactionHistory.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{applicantName}</td>
                          <td>{item.amount}</td>
                          <td>{item.payday}</td>
                          <td>Paid</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </div>
        )}
      </>
    );
}

export default ExpenseClaims;
