import { Component } from "react";
import RouteManager from "../../managers/RouteManager";
import {
    showToast,
    TOAST_TYPE,
    DKButton,
    showLoader,
    removeLoader,
    DKIcons,
    DKLabel,
    DKIcon,
    DKSpinner,
    DKTooltipWrapper,
    DKInput
} from "deskera-ui-library";
import { EXPENSE_CLAIM_STATUS, NEW_CLIAM_FORM_FILED } from "./ClaimsConstants";
import ExpenseService from "../../services/ExpenseService";
import moment from "moment";
import Utility from "../../utils/Utility";
import FileImporter from "../import/Import";
import Popup from "../common/Popup";
import { TENANT, TenantService } from "../../services/TenantMangerService";
import { getFileName } from "../../services/Config";
import ic_info_message from "../../assets/icons/ic_info_gray01.png";
import { isViewportLarge } from "../../utils/ViewportSizeUtils";
import { triggerDownload } from "../../utils/ExportData";
import ic_document from "../../assets/icons/ic_document.png";
import { ENTITY, INPUT_TYPE } from "../../utils/Constants";
import i18next from 'i18next';
export default class ClaimForm extends Component {
    isEdit = false;
    canValidate = false;
    isMultiSelect = false;
    claimFormField = NEW_CLIAM_FORM_FILED;
    categoryList = [];
    taxList = [];
    uploadedAttachments = []
    claimData = {
        attachments: [],
        currency: TenantService.get(TENANT.CURRENCY_CODE),
        id: null,
        status: EXPENSE_CLAIM_STATUS.DRAFT,
        taxAmount: "",
        otherDetails: ""
    }
    uploadedImg = {}
    isLoadFile = false;
    attachmentOCRPopup = false;
    fileList = null;
    selectedFile;
    isOCREnable = process.env.REACT_APP_OCR_API_CALL;
    newlyAddedAttachment = []
    deletedAttachmentIds = []
    constructor(props) {
        super(props)
        this.state = {

        }
        this.isOCREnable = this.isOCREnable == "true" ? this.isOCREnable : false
        this.claimId = this.props.claimId ? this.props.claimId : null;
        this.isEdit = this.props.isDuplicate ? false : this.props.claimId ? true : false;
        if (this.claimId) {
            showLoader();
            this.getClaim();
        }
        this.getCatogory();
        this.getTaxes()
    }
    componentDidMount() {
        RouteManager.setPresenter(this);
        this.getAttachmentsList();
    }
    // JS Logic Start////////
    getClaim() {
        ExpenseService.getExpenseById(this.claimId).then(data => {
            if (data) {
                this.claimData = data;
                this.claimData['attachmentIds'] = this.claimData['attachments'];
                this.claimData['spendDate'] = moment(this.claimData['spendDate']).format('YYYY-MM-DD')
                if (this.props.isDuplicate) {
                    this.claimId = null;
                    this.claimData['status'] = null;
                }
                if (data.attachments && data.attachments.length != 0 && data.receipts && data.receipts.length != 0) {
                    const url = data.receipts.filter(res=>!Utility.isEmpty(res))
                    data.attachments.forEach(attach=>{
                        let id = parseInt(attach)
                        if(Number.isNaN(id)) {
                            this.uploadedImg = {
                                relativePath: attach,
                                fileUrl: (url && url.length != 0) ? url[0]:''
                            }
                        }
                    })
                }
                this.updateState();
                removeLoader();
            }
        }, error => {
            console.log(error);
        })
    }
    getCatogory() {
        ExpenseService.getCategory(false).then(
            (response) => {
                if (response) {
                    this.categoryList = response;
                    let ind = this.claimFormField.findIndex(field => field.key === "categoryId");
                    this.claimFormField[ind].options = response;
                    this.updateState();
                }
            }, (error) => {
                if (error?.message === "User is not a part of any group") {
                    showToast(error.message, TOAST_TYPE.FAILURE);
                }
            }
        )
    }
    getTaxes() {
        ExpenseService.getTaxes().then((res) => {
            if (res && res.content) {
                this.taxList = res.content;
                let ind = this.claimFormField.findIndex(field => field.key === "taxCode");
                if (res?.content.length > 0 && ind != -1) {
                  this.claimFormField[ind].options = res.content;
                } else {
                    this.claimFormField[ind].uiVisible = false;
                }
                this.updateState();
            }
        },
            error => {
                console.log(error)
            })
    }
    onChange(value, index, obj) {
        switch (obj.key) {
            case "spendDate":
                this.claimData[obj.key] = moment(value).format('YYYY-MM-DD');
                break;
            case "categoryId":
                this.claimData[obj.key] = value.id ;
                break;
            case "taxCode":
                this.claimData[obj.key] = value.code;
                this.claimData['taxAmount'] = this.claimData['totalAmount']? ((this.claimData['totalAmount'] * value.percent) / 100) :0 ;
                break;
            case "totalAmount":
                if(!Utility.isEmpty(this.claimData['taxCode'])) {
                    let taxInd = this.taxList.findIndex(taxes=>taxes.code == this.claimData['taxCode']);
                    if( taxInd != -1 && this.taxList[taxInd]?.percent) {
                        this.claimData['taxAmount'] = this.claimData['taxCode']? ((value * this.taxList[taxInd]?.percent) / 100) :0 ;
                    }
                }
                this.claimData[obj.key] = value
                break;
            default:
                this.claimData[obj.key] = value;
                break;
        }
        this.updateState()
    }
    updateState() {
        this.setState({
            ...this.state
        })
    }
    validateForm() {
        let isValid = true;
        this.canValidate = true;
        this.claimFormField.forEach(field => {
            if (field.required && field.type != INPUT_TYPE.NUMBER && Utility.isEmpty(this.claimData[field.key])) {
                isValid = false;
            } else if (field.required && field.type == INPUT_TYPE.NUMBER && Utility.isEmpty(this.claimData[field.key].toString()) ) {
                isValid = false;
            } else if (!field.isValid) {
                isValid = false;
            }
        })
        this.updateState();
        return isValid
    }
    save() {
        if (!this.validateForm()) {
            return;
        }
        this.claimData['status'] = EXPENSE_CLAIM_STATUS.DRAFT;
        this.saveData();

    }
    saveData() {
        let attachmentIds=[];
        if(this.newlyAddedAttachment) {
            this.newlyAddedAttachment.forEach(files=>{
                attachmentIds.push(files.attachmentId)
            })
            this.claimData['attachmentIds'] = attachmentIds
            this.claimData['attachments'] = attachmentIds
        }
        if (this.uploadedImg.relativePath) {
            this.claimData['attachments'] = [...attachmentIds,this.uploadedImg.relativePath]
        }
        this.claimData['deletedAttachmentIds'] = this.deletedAttachmentIds
        if(this.props.isDuplicate) {
            delete this.claimData.approvalStages;   
        }
        showLoader();
        ExpenseService.saveExpenses(this.claimData, this.claimId).then(res => {
            removeLoader();
            if (this.claimData['status'] === EXPENSE_CLAIM_STATUS.DRAFT) {
                showToast('Expense has been saved as draft successfully.', TOAST_TYPE.SUCCESS);
            } else {
                showToast('Expense has been submitted successfully.', TOAST_TYPE.SUCCESS);
            }
            if (this.props.onClose) {
                this.props.onClose()
            }
        }, error => {
            removeLoader();
            if (this.claimData['status'] === EXPENSE_CLAIM_STATUS.DRAFT) {
                showToast(i18next.t("ERROR_EXPENSE_DRAFT"), TOAST_TYPE.FAILURE);
            } else {
                showToast(i18next.t("ERROR_EXPENSE_SUBMIT"), TOAST_TYPE.FAILURE);
            }
        })
    }
    submit() {
        if (!this.validateForm()) {
            return;
        }
        this.claimData['status'] = EXPENSE_CLAIM_STATUS.PENDING;
        this.saveData();
    }
    upload(files) {
        this.fileList = files.fileInfo;
        this.selectedFile = files;
        this.updateState()
    }
    mapOCRDataToForm(img) {
        let keys = Object.keys(img);
        keys.forEach(key=>{
            switch(key) {
                case 'extractedDates':
                    if(img[key].length !== 0) {
                        this.claimData['spendDate'] = moment(img[key][0]).format('YYYY-MM-DD')
                    }
                    break;
                case 'invoiceNumber':
                    break;
                case 'totalAmount':
                    this.claimData['totalAmount'] = img[key]
                    break;
                case 'tax':
                    break;
            }
        })

    }
    imagePreviewLoader(uploadedFileData, relativePath) {
        const reader = new FileReader();
        reader.readAsDataURL(uploadedFileData);
        reader.onload = e => {
            this.uploadedImg = {
                relativePath: relativePath,
                fileUrl: reader.result
            }
            showToast('File uploaded successfully.', TOAST_TYPE.SUCCESS)
            this.updateState()
        };
    }
    getFieldValue(obj) {
        switch (obj.columnCode) {
            case "categoryId":
                let data = this.categoryList.filter(res => res.id === this.claimData[obj.columnCode])
                return (data && data.length != 0) ? data[0] : [];
            case "taxCode":
                let taxData = this.taxList.filter(res => res.code === this.claimData[obj.columnCode])
                return (taxData && taxData.length != 0) ? taxData[0] : [];
            case 'spendDate':
                if (this.claimData[obj.columnCode]) {
                    return new Date(this.claimData[obj.columnCode]);
                } else {
                    this.claimData[obj.columnCode] = moment(new Date()).format('YYYY-MM-DD');
                    return new Date();
                }
            default:
                return this.claimData[obj.columnCode]
        }

    }
    saveDocument(file) {
        if(!this.selectedFile) {
            return
        }
        showLoader("Uploading document");
        let files = this.selectedFile;
        let fileContent = file ? file :files.fileContent
        this.attachmentPopup = false;
        var inputData = new FormData();
        inputData.append("file", fileContent);
        ExpenseService.upload(inputData).then(res => {
            if (res && res.relativePath) {
                ExpenseService.image(res.relativePath).then(img => {
                    removeLoader()
                    if(img && img.extracted) {
                        this.mapOCRDataToForm(img);
                        this.imagePreviewLoader(fileContent, res.relativePath)
                    } else {
                        showToast("Couldn't extract data from image, Please try filling information manually", TOAST_TYPE.WARNING)
                        this.updateState()
                    }

                }, error => {
                    removeLoader()
                    showToast("Couldn't extract data from image, Please try filling information manually", TOAST_TYPE.WARNING)
                    this.updateState()
                })
            }
        }, error => {
            removeLoader()
            console.log(error)
            this.fileList = null
            showToast("Failed to upload receipt", TOAST_TYPE.FAILURE)
            this.updateState()
        })
    }
    isDisableFields(value) {
        return value === EXPENSE_CLAIM_STATUS.PENDING || value === EXPENSE_CLAIM_STATUS.APPROVED|| value === EXPENSE_CLAIM_STATUS.REJECTED
    }
    getAttachmentsList() {
        ExpenseService.getAllAttachment(this.claimId).then(res=>{
            this.uploadedAttachments = res;
            this.setState({
                ...this.state
            })
        },error=>{
            console.log(error)
        })
    }
    downloadAttachment(attachmentObj) {
        ExpenseService.downloadAttachment(attachmentObj.attachmentId)
            .then((absolutePath) => {
                triggerDownload(null, attachmentObj.attachmentFileName, absolutePath);
            })
            .catch(() => {
                showToast('Something went wrong, while downloading the attachment.',TOAST_TYPE.FAILURE);
            });
    }
    deleteAttachment(attachmentObj) {
        if(attachmentObj.attachmentId) {


        ExpenseService.deleteAttachment(attachmentObj.attachmentId)
            .then((absolutePath) => {
                let ind = this.uploadedAttachments.findIndex(d=>d.attachmentId==attachmentObj.attachmentId)
                if(ind != -1) {
                    this.uploadedAttachments.splice(ind,1);

                } else {
                    this.getAttachmentsList()
                }
                this.deletedAttachmentIds.push(attachmentObj.attachmentId)
                this.updateState()
                showToast('Attachment Deleted Successfully',TOAST_TYPE.SUCCESS);
            })
            .catch(() => {
                showToast('Something went wrong, while deleting the attachment.',TOAST_TYPE.FAILURE);
            });
        } else {
            let ind = this.claimData['attachments'].findIndex(d => d == attachmentObj.relativePath)
            if (ind != -1) {
                this.claimData['attachments'].splice(ind, 1);
                this.uploadedImg = {}
                this.fileList = null;
                this.updateState()
            }
        }
    }
    // UI Logic Start ///////////////
    renderTop() {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <DKLabel text={`${this.isEdit ? "Edit" : "New"} Expense Claim`} className="fw-m fs-l" />
                    <div className="d-flex">
                        <DKButton
                            className="bg-gray1 border-m mr-s"
                            title={isViewportLarge() ? "Close" : ""}
                            icon={isViewportLarge() ? "" : DKIcons.ic_close}
                            onClick={() => {
                                if (this.props.onClose) {
                                    this.props.onClose()
                                }
                            }}
                        />
                        {!this.isDisableFields(this.claimData['status']) && (
                            <>
                                <DKButton
                                    className='bg-gray1 border-m mr-s'
                                    title="Save"
                                    onClick={() => this.save()}
                                />
                                <DKButton
                                    className='bg-app text-white'
                                    title="Submit"
                                    onClick={() => this.submit()}
                                />
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    }
    renderFormField(obj, index) {
        return (
            <>
                <DKInput
                    key={index}
                    className={"p-v-s mb-r"}
                    icon={obj.icon}
                    title={obj.name}
                    placeholder={obj.name}
                    value={this.getFieldValue(obj)}
                    options={obj.options}
                    type={obj.type}
                    errorMessage={obj.errorMessage}
                    labelClassName=" text-align-left "
                    canValidate={this.canValidate}
                    onChange={(value) => {
                        this.onChange(value, index, obj);
                    }}
                    validator={(value) => {
                        if (obj.validation && obj.validation.regExp) {
                            const regex = new RegExp(obj.validation.regExp);
                            obj.isValid = regex.test(value);
                            return regex.test(value);
                        } else if (obj.required && typeof value === "string" && Utility.isEmpty(value.trim())) {
                            obj.isValid = false
                            return false;
                        } else if (obj.required && Utility.isEmpty(value)) {
                            obj.isValid = false
                            return false;
                        } else {
                            obj.isValid = true;
                            return true;
                        }
                    }}
                    formatter={(obj) => {
                        /** How to render a selected option in case of object **/
                        return obj.name;
                    }}
                    dropdownConfig={{
                        className: "",
                        style: {},

                        data: obj.options,
                        renderer: (index, obj) => {
                            /** How to render value in Dropdown in case of object **/
                            return <DKLabel text={`${obj.name}`} />;
                        },
                        onSelect: (index, obj) => {

                        },
                    }}
                    direction={obj.direction}
                    required={obj.required}
                    readOnly={obj.readOnly || this.isDisableFields(this.claimData['status'])}
                />
            </>
        )
    }
    documentSection() {
        return (
            <div className="row row-responsive justify-content-between align-items-start position-relative p-v-s mb-r">
                {(this.uploadedImg.fileUrl ||
                    !this.uploadedImg.fileUrl && !this.isDisableFields(this.claimData['status'])) &&
                <div className="mr-l">
                    <div className="row-auto-width mb-s dkinput-header-section mt-s">
                        <DKLabel className=" unselectable" text="Document" />
                        <DKTooltipWrapper
                            content='Auto-fill your claim with OCR! Click on Upload.'
                            tooltipClassName="warning-tooltip-prop warning-tooltip-prop "
                        >
                            <DKIcon
                                src={ic_info_message}
                                className="ml-s ic-s ml-r mt-xs cursor-hand "
                                style={{ opacity: 0.4 }}

                            />
                        </DKTooltipWrapper>
                    </div>
                </div>}
                {!this.uploadedImg.fileUrl && !this.isDisableFields(this.claimData['status']) && <div className="row  dkinput-value-section ">
                    <DKButton
                        className=""
                        disabled={this.isDisableFields(this.claimData['status'])}
                        style={{paddingLeft:0}}
                        icon={(this.fileList && this.fileList.name) ? DKIcons.ic_download : DKIcons.ic_attachment}
                        title={"Upload"}
                        onClick={() => {
                            this.attachmentPopup = true;
                            this.fileList = null;
                            this.updateState();
                        }
                        }
                    />
                </div>}
                {this.uploadedImg.fileUrl &&
                    <div className="">
                        <img className="parent-width" src={this.uploadedImg.fileUrl} />
                        <div>
                            {!this.isDisableFields(this.claimData['status']) && <DKIcon className="ic-s mt-s mr-m cursor-hand" src={DKIcons.ic_edit}
                                onClick={() => {
                                    this.attachmentPopup = true;
                                    this.fileList = null;
                                    this.updateState();
                                }
                                } />}
                            <a href={this.uploadedImg.fileUrl} download={getFileName(this.uploadedImg.relativePath)}>
                                <DKIcon
                                    className="ic-s cursor-hand"
                                    src={DKIcons.ic_download}
                                    onClick={() => { }
                                    } />
                            </a>

                            {!this.isEdit && <DKIcon className="ic-s ml-m cursor-hand" src={DKIcons.ic_delete}
                                onClick={() => {
                                    this.claimData['attachments'] = []
                                    this.uploadedImg = {}
                                    this.fileList = null;
                                    this.updateState();
                                }
                                } />}
                        </div>
                    </div>
                }

            </div>
        )
    }
    fileuploader() {
        return (<>
            {this.attachmentPopup && <Popup >
                <div className="d-flex justify-content-between align-items-center">
                    <DKLabel text="Add Attachment" className="fw-m fs-l" />
                    <div className="d-flex">
                        <DKButton
                            className="bg-gray1 border-m mr-s"
                            title={"Close"}
                            onClick={() => { this.attachmentPopup = false; this.updateState() }}
                        />
                        <DKButton
                            className="bg-app text-white"
                            title="Save"
                            onClick={() => {
                                this.saveDocument()
                            }}
                        />
                    </div>
                </div>
                <div className=" justify-content-center d-flex mt-xl">
                    { <FileImporter
                        acceptFileFormat={[".jpeg", ".jpg", ".png"]}
                        fileInfo={this.fileList}
                        getFile={(param) => this.upload(param)}
                        title="Auto-fill your claim with OCR! Just drop a receipt here or browse local files to upload"
                        isForPopup={true}
                        isMultiSelect={this.isMultiSelect}
                    />}

                    {this.isLoadFile && <DKSpinner className="pt-xxl pb-xxl" />}
                </div>
            </Popup>}
        </>
        )
    }
    triggerAttachmentUpload = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.addEventListener('change', (e) => {
          const target = e.target ;
          target?.files &&
            Array.from(target.files).forEach((file) =>{
                let obj = {
                    entityName: ENTITY.EXPENSE
                }
                // here need conditinal call for OCR
                if(this.isOCREnable) {
                    this.selectedFile = file
                    this.saveDocument(file)
                }
                ExpenseService.uploadAttachment(file,obj).then(res=>{

                    if(res && res.attachmentId) {
                        this.uploadedAttachments.push(res)
                        this.newlyAddedAttachment.push(res)
                        this.updateState()
                    }
                },
                error=>{
                    console.log(error)
                    showToast(i18next.t("ERROR_UPLOAD"),TOAST_TYPE.FAILURE);
                })
            }
            );
        });
        input.click();
      };
    attachments() {
        return (
            <div className="row row-responsive align-items-start position-relative p-v-s mb-r">
                <div className="" style={{ width: '46%' }}>
                    <div className="row-auto-width mb-s dkinput-header-section mt-s">
                        <DKLabel className=" unselectable" text="Attachment" />

                    </div>
                </div>

                <div className="attachment-file-w" >
                    <DKButton
                        title={`+ ${i18next.t("ATTACH_FILES")}`}
                        className="pl-0 text-app fw-m"
                        onClick={()=>{
                            this.triggerAttachmentUpload()
                        }}
                    />
                    {this.uploadedAttachments.map(attachment => {
                        return (
                            <div className="parent-width">
                                <div className="d-flex border-m border-radius-s p-s bg-gray1 mb-s">
                                    <DKIcon className="ic-s my-auto cursor-hand" src={ic_document}
                                        onClick={() => this.downloadAttachment(attachment)}
                                    />
                                    <span className="m-h-s parent-width cursor-hand "
                                        style={{
                                            overflow: "hidden"
                                        }}
                                        onClick={() => this.downloadAttachment(attachment)}>
                                        {attachment?.attachmentFileName}
                                    </span>
                                    {!this.isDisableFields(this.claimData['status']) && (
                                        <DKIcon className="ic-s my-auto cursor-hand" src={DKIcons.ic_delete}
                                            onClick={() => this.deleteAttachment(attachment)} />
                                    )}

                                </div>
                            </div>
                        )
                    })}
                    {
                        this.uploadedImg.fileUrl && <div className="parent-width">
                        <div className="d-flex border-m border-radius-s p-s bg-gray1 mb-s">

                            <a className="parent-width app-font left-menu-button" href={this.uploadedImg.fileUrl} download={getFileName(this.uploadedImg.relativePath)}>
                                <div className="row parent-width ">
                                <DKIcon
                                    className="ic-s cursor-hand"
                                    src={ic_document}
                                    onClick={() => { }
                                    } />
                                    <DKLabel text={i18next.t("RECEIPT")} className="m-h-s"/>
                                </div>
                            </a>
                            {!this.isDisableFields(this.claimData['status']) && (
                                <DKIcon className="ic-s my-auto cursor-hand" src={DKIcons.ic_delete}
                                    onClick={() => this.deleteAttachment(this.uploadedImg)} />
                            )}

                        </div>
                    </div>
                    }
                </div>

            </div>
        )
    }
    renderForm() {
        return (
            <div className=" parent-width mt-l ">
                <div className="">
                    {false && <div className="d-flex justify-content-between">
                        <DKButton
                            className="bg-gray1 border-m"
                            title="Single"
                            disabled={true}
                        />
                        <div className="d-flex">
                            <DKButton
                                className="bg-gray1 border-m mr-m"
                                disabled={this.isDisableFields(this.claimData['status'])}
                                title="Save"
                                onClick={() => {
                                    this.save()
                                }}
                            />
                            <DKButton
                                className="bg-app text-white"
                                title="Submit"
                                disabled={this.isDisableFields(this.claimData['status'])}
                                onClick={() => {
                                    this.submit()
                                }}
                            />
                        </div>
                    </div>}

                    <div className="parent-width ">
                        <div className="">
                            {this.claimFormField.map((obj, index) => {
                                return (obj.uiVisible && (this.renderFormField(obj, index)))
                            })}
                        </div>
                        {/* Hide this feature for now
                        {this.documentSection()} */}
                        {this.attachments()}
                    </div>
                </div>
            </div>
        )
    }
    render() {
        return (
            <>

                <Popup className={""}>
                <div className="parent-width">
                    {this.renderTop()}
                    {this.renderForm()}
                </div>
                </Popup>
                {this.fileuploader()}
            </>
        )
    }
}