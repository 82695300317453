import http from "../http";
import ApiConstants from "../utils/ApiConstants";

class UserManagementService {
    static getUsers(isAdmin) {
        return http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.PAYOUTS.GET_USERS}${isAdmin ? "/admin" : ""}`).catch(
            (error) => {
                console.error('Error while fetching users: ', error);
                return Promise.reject(error);
            }
        );
    }

    static updateUsers(payload) {
        return http.patch(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.PAYOUTS.UPDATE_USERS}`, payload).catch(
            (error) => {
                console.error('Error while expense payout: ', error);
                return Promise.reject(error);
            }
        );
    }
}

export default UserManagementService;
