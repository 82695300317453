import http from "../http";
import ApiConstants from "../utils/ApiConstants";
import { TENANT, TenantService } from "./TenantMangerService";

class PayoutLogsService {
    static getPayouts() {
        return http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.PAYOUTS.GET_PAYOUTS}`);
    }
}

export default PayoutLogsService;
