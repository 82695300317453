import http from "../http";
import ApiConstants from "../utils/ApiConstants";
import { TENANT, TenantService } from "./TenantMangerService";

class PaypalService {
    static checkPaypalConnected() {
        http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.PAYPAL.REDIRECT}`).then(
            (data) => {
                TenantService.set(TENANT.IS_PAYPAL_CONNECTED, data.connected);
            }
        );;
    }

    static connectPaypal() {
        return http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.PAYPAL.REDIRECT}`);
    }

    static connectByScope(params) {
        return http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.PAYPAL.CONNECT}${ params}`);
    }

    static disconnectPaypal() {
        return http.get(`${ApiConstants.URL.BASE}${ApiConstants.URL.EXPENSE.PAYPAL.DISCONNECT}`);
    }
}

export default PaypalService;
