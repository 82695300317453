import { FORMAT } from "../utils/Constants";
import { COUNTRY } from "../utils/CountryConstant";
import CompanyDetailService from "../services/CompayDetailService";
import Table from "../services/Table";
import Tenant from "../services/Tenant";
import {
  removeLoader,
  showLoader,
  showToast,
  TOAST_TYPE,
} from "deskera-ui-library";

export const TENANT = {
  TENANT_WORK_WEEK: "TENANT_WORK_WEEK",
  USER_ID: "USER_ID",
  USER_NAME: "USER_NAME",
  USER_ROLE: "USER_ROLE",
  FIRST_NAME: "FIRST_NAME",
  LAST_NAME: "LAST_NAME",
  EMAIL_ID: "EMAIL_ID",
  FULL_NAME: "FULL_NAME",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  IS_ADMIN: "IS_ADMIN",
  DESIGNATION: "DESIGNATION",
  DATE_OF_CREATION: "DATE_OF_CREATION",
  COUNTRY_CODE: "COUNTRY_CODE",
  CURRENCY_CODE: "CURRENCY_CODE",
  ORGANIZATION_NAME: "ORGANIZATION_NAME",
  CONFIG: "CONFIG",
  DATE_FORMAT: "DATE_FORMAT",
  SHORT_DATE_FORMAT: "SHORT_DATE_FORMAT",
  DECIMAL_FORMAT: "DECIMAL_FORMAT",
  ERP_INTEGATION_SETTING: "ERP_INTEGATION_SETTING",
  IS_CONTRACTOR: "IS_CONTRACTOR",
  IS_DIRECT_PAY_ENABLED: "IS_DIRECT_PAY_ENABLED",
  IS_CHECKHQ_INTEGRATION: "IS_CHECKHQ_INTEGRATION",
  IS_CHECKHQ_COMPANY_CREATED: "IS_CHECKHQ_COMPANY_CREATED",
  CHECKHQ_COMPANY_TYPE: "CHECKHQ_COMPANY_TYPE",
  IS_PAYPAL_CONNECTED: "IS_PAYPAL_CONNECTED",
  IAM_USER_ID: "IAM_USER_ID",
  EXPENSE_ROLE: "EXPENSE_ROLE"
};

export class TenantService {
  static tenantData = {
    TENANT_WORK_WEEK: [],
    USER_ID: "",
    USER_NAME: "",
    USER_ROLE: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    EMAIL_ID: "",
    FULL_NAME: "",
    IS_ADMIN: false,
    DESIGNATION: "",
    DATE_OF_CREATION: "",
    COUNTRY_CODE: "",
    CURRENCY_CODE: "",
    ORGANIZATION_NAME: "",
    CONFIG: "",
    DATE_FORMAT: "",
    SHORT_DATE_FORMAT: "",
    DECIMAL_FORMAT: "",
    ERP_INTEGATION_SETTING: false,
    IS_CONTRACTOR: false,
    IS_DIRECT_PAY_ENABLED: false,
    IS_CHECKHQ_INTEGRATION: false,
    IS_CHECKHQ_COMPANY_CREATED: false,
    CHECKHQ_COMPANY_TYPE: "",
    IS_PAYPAL_CONNECTED: false,
    EXPENSE_ROLE: "",
    IAM_USER_ID: ""
  };

  static getTenantData() {
    Table.getTenantData().then(
      (response) => {
        if (response["tenant"]) {
          this.tenantData[TENANT.TENANT_WORK_WEEK] =
            response["tenant"]["workWeekDetails"];
          this.tenantData[TENANT.DATE_OF_CREATION] =
            response["tenant"]["dateOfCreation"];
          this.tenantData[TENANT.COUNTRY_CODE] =
            response["tenant"]["countryCode"];
          this.tenantData[TENANT.CURRENCY_CODE] =
            response["tenant"]["currency"];
          this.tenantData[TENANT.ORGANIZATION_NAME] =
            response["tenant"]["organizationName"];
          this.tenantData[TENANT.IS_DIRECT_PAY_ENABLED] =
            response["tenant"]["isAddBankAcc"];
          this.tenantData[TENANT.IS_CHECKHQ_INTEGRATION] =
            response["tenant"]["checkHQIntegration"];
          this.tenantData[TENANT.IS_CHECKHQ_COMPANY_CREATED] =
            response["tenant"]["checkHQCompanyCreated"];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  static getTenantConfig() {
    CompanyDetailService.getTenantConfig().then(
      (response) => {
        if (response) {
          this.tenantData[TENANT.CONFIG] = response;
          CompanyDetailService.setDateFormat(response["dateFormat"]);
          CompanyDetailService.setDecimalFormat(response["decimalFormat"]);
        }
      },
      (error) => {
        if (TenantService.get(TENANT.COUNTRY_CODE) === COUNTRY.US) {
          CompanyDetailService.setDateFormat(FORMAT.MMDDYYYY);
        } else {
          CompanyDetailService.setDateFormat(FORMAT.DDMMYYYY);
        }
      }
    );
  }

  static async getUserData() {
    showLoader();
    return await Tenant.getUserDetails().then(
      (response) => {
        removeLoader();
        this.tenantData[TENANT.USER_ID] = response["user"]["userId"];
        this.tenantData[TENANT.IAM_USER_ID] = response["user"]["iamUserId"];
        this.tenantData[TENANT.DESIGNATION] = response["user"]["designation"];
        this.tenantData[TENANT.EMAIL_ID] = response["user"]["emailId"];
        this.tenantData[TENANT.FIRST_NAME] = response["user"]["firstName"];
        this.tenantData[TENANT.LAST_NAME] = response["user"]["lastName"];
        this.tenantData[TENANT.IS_CONTRACTOR] =
          response["user"]["type"] == "CONTRACTOR" ? true : false;
        let isAdmin = false;
        for (const role of response["user"].roles) {
          if (role.appName === "PEOPLE") {
            if (role.name === "ADMIN" || role.name === "OWNER") {
              isAdmin = true;
              break;
            }
          }
        }

        this.tenantData[TENANT.IS_ADMIN] = isAdmin;
        dispatchEvent(
          new CustomEvent("onSideBarLoad", { detail: { isSideBarLoad: true } })
        );
        // Promise.resolve();
      },
      (error) => {
        showToast("Internal Server Error", TOAST_TYPE.FAILURE);
        removeLoader();
        // Promise.reject();
      }
    );
  }

  static setData(tenantData) {
    this.tenantData = tenantData;
  }

  static clear() {
    this.setData({});
  }

  static getData() {
    return this.tenantData;
  }

  static get(field) {
    return TenantService.tenantData[field];
  }

  static set(field, value) {
    this.tenantData[field] = value;
  }
}
