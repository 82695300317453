import ApiConstants from "../utils/ApiConstants";

import { removeLoader, showLoader } from "deskera-ui-library";
import http from "../http";

import TenantManager from "../managers/TenantManager";
import { TENANT, TenantService } from "./TenantMangerService";
import { CONSTANTS } from "../utils/Constants";

class Tenant {
  static getTenantDetails(params) {
    return http.get(ApiConstants.URL.TENANT.GET_DETAILS, { params }).then(
      (res) => {
        TenantManager.setTenantDetails(res);
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getOrgWithoutSetup(params) {
    showLoader("Creating new organisation...");
    return http
      .post(ApiConstants.URL.TENANT.ORG_WITHOUT_SETUP, {
        tenantName: params.tenantName,
        isBookkeeper: params.isBookkeeper,
        refreshToken: params.refreshToken,
      })
      .then(
        (res) => {
          removeLoader();
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      );
  }

  static addTenant(payload) {
    return http.post(ApiConstants.URL.TENANT.ADD_ORG, payload);
  }

  static getAllTenants() {
    return http.get(ApiConstants.URL.TENANT.TENANTS).then(
      (res) => {
        TenantManager.setAllTenants(res);
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getUserDetails() {
    return http.get(ApiConstants.URL.PEOPLE.USER);
  }

  static getCurrencyEnum(params = null) {
    if (!params)
      params = { limit: 200, page: 0, sortDir: "ASC", sortBy: "currencyName" };

    return http.get(ApiConstants.URL.CURRENCY.GET_ENUM, { params });
  }

    static setRolesDataForExpense() {
        return http.get(ApiConstants.URL.EXPENSE.GET_USER_ROLES).then(
            (response) => {
                let role = "EMPLOYEE";
                if (response?.groupRolesList?.length > 0) {
                    for (let i = 0; i < response.groupRolesList[0]?.roles?.length; i++) {
                        if (response.groupRolesList[0].roles[i] == CONSTANTS.ADMIN) {
                            role = CONSTANTS.ADMIN;
                            break;
                        } else if (response.groupRolesList[0].roles[i] == CONSTANTS.MANAGER) {
                            role = CONSTANTS.MANAGER;
                        }
                    }
                }

                TenantService.set(TENANT.EXPENSE_ROLE, role);
            }, (error) => {}
        );
    }
}

export default Tenant;
