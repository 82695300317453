import { COUNTRY } from "./CountryConstant";


export const PAGE_ROUTES = {
  DASHBOARD: "/",
  PUBLIC_PAGE: "/public/:id",
};

export const VIEWPORT_SIZES = {
  EXTRA_SMALL: "xs",
  SMALL: "sm",
  LARGE: "lg",
};

export const BREAKPOINTS = {
  SMALL: 560,
  LARGE: 800,
};

export const FORMAT = {
  DDMMYYYY: "DD-MM-YYYY",
  DDMMYYYY_2: "DD/MM/YYYY",
  DDMM: "DD-MM",
  DDMM_2: "DD/MM",
  MMDDYYYY: "MM-DD-YYYY",
  MMDDYYYY_2: "MM/DD/YYYY",
  MMDD: "MM-DD",
  MMDD_2: "MM/DD",
  YYYYMMDD: "YYYY-MM-DD",
  YYYYMMDD_2: "YYYY/MM/DD",
  YYYYMMDD_HHMM: "YYYY-MM-DD HH:mm",
  HHMM: "HH:mm",
  HHMM_A: "HH:mm A",
  HHMM_A_2: "hh:mm A",
};

export const INPUT_TYPE = {
  TEXT: "text",
  // PASSWORD: "password",
  NUMBER: "number",
  EMAIL: "email",
  DATE: "date",
  URL: "text",
  SELECT: "select",
  MULTI_SELECT: "multi-select",
  CHECKBOX: "checkbox",
  BUTTON: "button",
  MORE: "more",
  DROPDOWN: "dropdown",
  AUTOCOMPLETE: "autocomplete",
  TOGGLE: "toggle",
};

export const INPUT_FIELD_MAP = {
  ["text"]: INPUT_TYPE.TEXT,
  ["number"]: INPUT_TYPE.NUMBER,
  ["dropdown"]: INPUT_TYPE.DROPDOWN,
  ["multiselect"]: INPUT_TYPE.MULTI_SELECT,
  ["checkbox"]: INPUT_TYPE.CHECKBOX,
  ["select"]: INPUT_TYPE.SELECT,
};

export const DEFAULT_TRIAL_PLAN_DAYS = 15;

export const FILE_TYPE = {
  PDF: "pdf",
  JPG: "jpg",
  JPEG: "jpeg",
  PNG: "png",
  SVG: "svg",
  TXT: "txt",
  BMP: "bmp",
  DOC: "doc",
  DOCX: "docx",
  XLSX: "xlsx",
  XLS: "xls",
  CSV: "csv",
  PPTX: "pptx",
  PPT: "ppt",
};

export const COUNTRY_CODE = {
  INDIA: "IN",
  US: "US",
};

export const COMPANY_DATE_FORMATS = [
  {
    value: "dd-MM-yyyy",
    label: "DD-MM-YYYY",
    short: "DD-MM",
  },
  {
    value: "MM-dd-yyyy",
    label: "MM-DD-YYYY",
    short: "MM-DD",
  },
  {
    value: "yyyy-MM-dd",
    label: "YYYY-MM-DD",
    short: "MM-DD",
  },
];

export const MAIN_HOLDER_ID = "root";
export const MAX_SECTION_WIDTH = 1200;
export const CHART_HEIGHT = 220;
export const CHART_WIDTH = 418;
export const APP_NAME = "PEOPLE";
export const URL_V1 = "v1/";
export const CUSTOM_DOMAIN_WEBSITE = "CRM3_CUSTOM_DOMAIN";
export const CUSTOM_DOMAIN_TIME = "5 minutes";
export const REGEX = {
  WEBSITE_DOMAIN:
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
};
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const MOBILE_APP_ACTIONS = {
  HOME: "HOME",
};

export const LANGUAGES_CODE = {
  ENGLISH_US: "en",
  ENGLISH_UK: "en_GB",
};

export const LANGUAGES = {
  ENGLISH_US: "English (US)",
  ENGLISH_UK: "English (UK)",
};

export const CHART_INTERVALS_CLAIMS_TREND = [
  {
    name: "This Week",
    interval: "weekly",
    searchKey: "WEEK",
  },
  {
    name: "This Month",
    interval: "monthly",
    searchKey: "MONTH",
  },
  {
    name: "This Quarter",
    interval: "quarterly",
    searchKey: "QUARTER",
  },
  {
    name: "This Year",
    interval: "yearly",
    searchKey: "YEAR",
  },
];

export const ENTITY = {
  EXPENSE: "EXPENSE",
};

export const CONSTANTS = {
  EMPTY_STRING: "",
  COLUMN_OPERATOR: " : ",
  EMPTY_STRING_WITH_SPACE: " ",
  REQUIRED: "required",
  STRING: "string",
  AMOUNT: "Amount",
  ACTION: "Action",
  PLUS_SYMBOL_CODE: "%2B",
  REPORT_NO_ROUTE: "noRoute",
  EXPENSES: "Expenses",
  CURRENT_LIABILITY: "Current Liabilities",
  EXPENSE: "expense",
  LIABILITY: "liability",
  ACTIVE: "active",
  INACTIVE: "inactive",
  OTHER_EXPENSES: "Other Expenses",
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  USER: "USER",
};

export const MESSAGES = {
  W2_TOOLTIP: "Includes Copy A, Copy B, Copy C, Copy D and Copy 2",
  MISC_TOOLTIP: "Includes Copy A, Copy B, Copy C, Copy 1 and Copy 2",
  START_DATE_ERR: "Start date should be less than End date",
  END_DATE_ERR: "End date should be greater than Start date",
};

export const COLUMN_CODES = {
  DATE_OF_JE: "dateOfJE",
  DATE_OF_REVERSAL_JE: "dateOfReversalOfJE",
};

export const CANDIDATE_ACCEPT_FILE_TYPE = [
  "image/*",
  "application/pdf",
  ".txt",
  ".doc",
  ".xls",
  " .ppt",
  ".docx",
  ".xlsx",
  ".pptx",
  ".jpg",
  ".jpeg ",
  ".png",
  ".gif",
  ".csv",
];

export const IMPORT_USERS_COUNTRY = [COUNTRY.IN, COUNTRY.US];

export const HIDE_CHAT_BUBBLE_CLASS = 'hide-chat-bubble';
export const REACT_TOOLTIP_CONST = {
  backgroundColor:"#fef6e1", borderColor:"#dcdcdc", textColor:"black", border:true
}