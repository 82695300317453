import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import RouteManager from "../../managers/RouteManager";
import { TableManger, TABLES, TABLE_DISPLAY_NAME } from "../../managers/TableManager";
import { parseColumnConfig, parseVisibleColumnConfig, UsersColumnConfig } from "../../services/Config";
import { TENANT, TenantService } from "../../services/TenantMangerService";
import UserManagementService from "../../services/UserManagementService";
import Utility, { removeDelayedLoader, showDelayedLoader } from "../../utils/Utility";
import DataGridHolderPayroll from "../common/DataGridHolderPayroll";
import { PAYMENT_METHOD } from "./UserManagementConstants";

function UserList(props) {
    const history = useHistory();

    const [users, setUsers] = useState(null);
    const [gridData, setGridData] = useState({
        columnData: [],
        rowData: [],
        originalData: [],
        filter: []
    });

    useEffect(() => {
        RouteManager.setPresenter({ props: { ...props, history: history } });

        fetchUsers();
    }, []);

    useEffect(() => {
        if (!Utility.isEmptyObject(users)) {
            const rowData = UsersColumnConfig(users);
            const columnConfig = parseVisibleColumnConfig(parseColumnConfig(TableManger.getTableVisibleColumns(TABLES.PEOPLE_EXPENSE_USER_MANAGEMENT), TABLES.PEOPLE_EXPENSE_USER_MANAGEMENT));

            const gridData = {
                columnData: columnConfig,
                rowData: rowData,
                filter: [],
                originalData: rowData
            };

            setGridData(gridData);
        }
    }, [users]);

    const fetchUsers = () => {
        const timeout = showDelayedLoader();
        UserManagementService.getUsers(TenantService.get(TENANT.IS_ADMIN)).then(
            (response) => {
                setUsers(response);
                removeDelayedLoader(timeout);
            }, (error) => {
                setUsers([]);
                removeDelayedLoader(timeout);
            }
        );
    }

    const onRowUpdate = (data) => {
        if (!data?.rowData) {
            return;
        }

        const payload = {
            userId: data.rowData.userId,
            iamUserId: data.rowData.iamUserId,
            expensePayoutMethod: PAYMENT_METHOD[data.rowData.expensePayoutMethod],
            paypalEmail: data.rowData.paypalEmail,
            paypalId: "ABCDEFGJGUTHF"
        }

        UserManagementService.updateUsers(payload).then(
            (response) => {
                console.log(response);
            }, (error) => {
                console.log(error);
            }
        );
    }

    return (
        <Fragment>
            <div className="parent-width parent-height">
                <div className="column" style={{ height: "90%" }}>
                    <div className="flex-1 position-relative" style={{ minHeight: "200px" }}>
                        <DataGridHolderPayroll
                            tableName={TABLES.PEOPLE_EXPENSE_USER_MANAGEMENT}
                            displayTableName={TABLE_DISPLAY_NAME[TABLES.PEOPLE_EXPENSE_USER_MANAGEMENT]}
                            allowColumnEdit={false}
                            allowColumnSort={false}
                            allowRowEdit={true}
                            onRowOpenBtnHidden={true}
                            gridData={gridData}
                            onSelection={(selection) => {}}
                            onFilter={(dataList, queries) => {}}
                            onRowUpdate={(data) => {
                                onRowUpdate(data);
                            }}
                            addButtonConfig={[]}
                            addMenuButton={[]}
                            onPaginationClick={(requestedPageNumber) => {}}
                            onCancelContext={() => {}}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UserList;
