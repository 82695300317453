import { Component } from "react";
import { DKLabel, DKButton, DKInput, showToast, TOAST_TYPE,INPUT_TYPE,INPUT_VIEW_DIRECTION } from "deskera-ui-library";
import Utility from "../../utils/Utility";
import ExpenseService from "../../services/ExpenseService";
import { showAlertEmployeeExpense } from "../../managers/common/CommonUtil";


export default class AddGroupUser extends Component {
    canValidate = false;
    allUsers;
    groupId;
    expenseData = {
        employee: []
    }
    constructor(props) {
        super(props)
        this.groupId = props.data

        this.state = {
            expenseData: {
                employee: []
            },
            allUsers: [],
            selectedUsers: undefined
        }
    }

    componentDidMount(){
        ExpenseService.getNonGroupMembers().then(
            (response) => {
                if (response && response.length > 0) {
                    const users = response.map((u) => {
                        return {
                            ...u,
                            name: String().concat(u.firstName, ' ', u.lastName)
                        }
                    });
                    this.setState({
                        allUsers: users
                    });
                } else {
                    this.setState({
                        allUsers: []
                    });
                }
            }, (error) => {
                this.setState({
                    allUsers: []
                });
            }
        );

        this.updateState();
    }

    updateState() {
        this.setState({
            ...this.state
        })
    }

    updateSelectedUsers(){
        const options = [];

        this.state.expenseData.employee.forEach(e => {
            const index = this.state.allUsers.findIndex(o => o.id == e.userId)
            if (index != -1) {
                options.push(index)
            }
        });

        this.setState({
            selectedUsers: options
        });
    }

    submit() {
        this.canValidate = true;
        this.updateState()
        if (this.state.expenseData.employee && this.state.expenseData.employee.length == 0) {
            return;
        }

        const employee = this.state.expenseData.employee.map(e => {
            e.groupId = this.groupId;
            return e;
        });
        let iamUserIds = this.state.expenseData.employee.map(e=>e.iamUserId);
        ExpenseService.isUserInExpenseApproval(iamUserIds).then(res => {
            ExpenseService.expenseBulkUser(employee).then(r => {
                showToast("Saved details successfully", TOAST_TYPE.SUCCESS);
                this.props.onClose(true)
            }, error => {
                if (error && error.message) {
                    showToast(error.message, TOAST_TYPE.FAILURE);
                } else {
                    showToast('Internal server error', TOAST_TYPE.FAILURE);
                }
            })
        }, error => {
            showAlertEmployeeExpense('move')

        })
    }
    onChange(value) {
        let arr = []
        value.forEach(ind => {
            let empObj = {

                role: "employee"
            }
            empObj['userId'] = this.state.allUsers[ind].id;
            empObj['iamUserId'] = this.state.allUsers[ind].iamUserId;
            arr.push(empObj)
        });

        this.setState({
            expenseData: {
                employee: arr
            }
        }, () => {
            this.updateSelectedUsers();
        });
    }
    renderTop() {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <DKLabel text={`Add Member`} className="fw-m fs-l" />
                    <div className="d-flex">
                        <DKButton
                            className="bg-gray1 border-m mr-s"
                            title="Close"
                            onClick={() => {
                                if (this.props.onClose) {
                                    this.props.onClose()
                                }
                            }}
                        />

                        <DKButton
                            className={`bg-app text-white  `}
                            title="Submit"

                            onClick={() => {
                                this.submit()
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }
    renderUserListField() {
        return (
            <>
                <DKInput
                    displayKey="name"
                    className={"p-v-s mb-r"}

                    title={'Add Member'}
                    placeholder={'Add Member'}
                    value={this.state.selectedUsers}
                    // options={obj.options}
                    type={INPUT_TYPE.DROPDOWN}

                    labelClassName=" text-align-left "
                    canValidate={this.canValidate}
                    onChange={(value) => {
                        this.onChange(value, 'employee');
                    }}
                    validator={(value) => {
                        if (Utility.isEmpty(value)) {
                            // obj.isValid = false
                            return false;
                        } else {
                            // obj.isValid = true;
                            return true;
                        }
                    }}
                    formatter={(obj) => {
                        /** How to render a selected option in case of object **/
                        return `${obj.firstName} ${obj.lastName}`;
                    }}
                    dropdownConfig={{
                        className: "",
                        style: {},
                        allowSearch:true,
                        searchableKey:"name",
                        checkMarkColor: "bg-app",
                        data: this.state.allUsers,
                        multiSelect: true,
                        renderer: (index, obj) => {
                            /** How to render value in Dropdown in case of object **/
                            return <DKLabel text={`${obj.firstName} ${obj.lastName}`} />;
                        },
                        onSelect: (index, obj) => {
                            // onselect
                        },
                    }}
                    direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
                    required={true}
                    readOnly={false}
                />
            </>
        )
    }
    render() {
        return (
            <>
                {this.renderTop()}
                <div className="mt-l">
                    {this.renderUserListField()}
                </div>
            </>
        )
    }
}