import { Component } from "react";
import RouteManager from "../../managers/RouteManager";

import {
  DKIcons,
  DKIcon,
  DKButton,
  DKLabel,
  DKStatus,
  showToast,
  TOAST_TYPE,
  DKTooltipWrapper,
  DKCheckMark
} from "deskera-ui-library";
import ApiConstants from "../../utils/ApiConstants";
import AppManager from "../../managers/AppManager";
import FeedbackPopup from "../common/FeedbackPopup";
import ic_credit_card from "../../assets/icons/ic_credit_card.png";
import { isUSCompliance } from "../../managers/common/CommonUtil";
import PaypalConnect from "../paypal/PaypalConnect";
import { TENANT, TenantService } from "../../services/TenantMangerService";
import TenantManager from "../../managers/TenantManager";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
        needFeedbackPopup: false,
        showSelectOrgPopUp: false,
        showPaypalConnectPopup: false,
        isPaypalConnected: TenantService.get(TENANT.IS_PAYPAL_CONNECTED),
        isCheckHqPaymentEnabled: false
    };

    this.settingOptions = [
      {
        title: "My Profile",
        description:
          "See your profile details and explore our other products too",
        icon: DKIcons.ic_user,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO,
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Organization settings",
        description: "You can update or modify your organisation details here",
        icon: DKIcons.ic_home,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "settings",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "User Management",
        description:
          "Add or modify users to your organisation. Users will be listed here",
        icon: DKIcons.ic_user_2,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "users",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Permissions",
        description:
          "Assign roles and permission to oraganisation's user different products",
        icon: DKIcons.ic_lock,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "users",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Subscription / Billing",
        description: "Manage your subscription, change plan and upgrade here",
        icon: DKIcons.ic_cards,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "billing",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Two-Factor Authentication (2FA)",
        description:
          "Increase your account's security by enabling Two-Factor Authentication (2FA).",
        icon: DKIcons.ic_key,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "security",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Change Password",
        description: "You can change or modify your password here",
        icon: DKIcons.ic_key_2,
        iconTitle: "",
        url: ApiConstants.PRODUCT_URL_GO + "security",
        buttonTitle: "View details",
        navigateTo: true,
        hasInfo: false,
        isVisible: true,
        redirectTo: "_blank",
      },
      {
        title: "Configure Paypal Account",
        description: "You can view/modify paypal account configuration details here",
        icon: ic_credit_card,
        iconTitle: "Configure Payout Account",
        url: "CONNECT_PAYPAL",
        buttonTitle: "View details",
        navigateTo: false,
        hasInfo: true,
        isVisible: ApiConstants.PAYPAL_PAYOUT_ENABLED && isUSCompliance(),
        redirectTo: "_self",
      },
      {
        title: "Enable CheckHQ Payouts",
        description: "Please enable checkhq intergation to use checkhq for expense payouts",
        icon: ic_credit_card,
        iconTitle: "Configure CheckHQ Payouts",
        url: "PAYMENT_OPTIONS",
        buttonTitle: "",
        navigateTo: false,
        hasInfo: true,
        isVisible: ApiConstants.CHECKHQ_PAYOUT_ENABLED && isUSCompliance(),
        redirectTo: "_self",
      }
    ];
  }

  componentDidMount() {
    RouteManager.setPresenter(this);

    const tenantData = TenantManager.getPeopleTenantData();
    if (tenantData) {
        this.setState({
            isCheckHqPaymentEnabled: tenantData.tenant.isCheckhqOffCyclePaymentEnabled
        });
    }
  }

  onDisconnect = () => {
    this.setState({
        isPaypalConnected: false,
        showPaypalConnectPopup: false
    });
    TenantService.set(TENANT.IS_PAYPAL_CONNECTED, false);
  }

  onClose = () => {
    this.setState({
        isPaypalConnected: TenantService.get(TENANT.IS_PAYPAL_CONNECTED),
        showPaypalConnectPopup: false
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="parent-width">
        <DKLabel className="fs-l fw-m" text="Settings" />
        {this.getHelpSection()}
        <div className="row flex-wrap mt-l align-items-stretch">
          {this.getAllCards()}
        </div>
        {this.getFooter()}
        {this.state.needFeedbackPopup && (
          <FeedbackPopup onCancel={this.hideFeedbackPopup} />
        )}
        {this.state.showPaypalConnectPopup && (
            <PaypalConnect
                showPaypalConnect={true}
                onDisconnect={this.onDisconnect}
                onClose={this.onClose}
            />
        )}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  getAllCards() {
    let arr = [];
    this.settingOptions.forEach((obj, index) => {
      if (obj.isVisible) {
        arr.push(this.getCard(obj, index));
      }
    });
    return arr;
  }

  routeToPage(url, navigateTo, redirectTo) {
    if (navigateTo) {
      window.open(url, redirectTo);
    } else if (url === "CONNECT_PAYPAL") {
        this.setState({
            showPaypalConnectPopup: true
        });
    }
  }

  showOrgSelectPopup() {
    this.setState({
      showSelectOrgPopUp: true,
    });
  }

  async copySelectedValue(value) {
    try {
      await navigator.clipboard.writeText(value);
      showToast("Copied", TOAST_TYPE.DEFAULT);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  getCard(setting, index) {
    return (
      <>
        <div
          className="bg-white border-radius-m border-m p-l mr-xl mb-xl column border-box shadow-s cursor-hand mobile-parent-width"
          style={{ minWidth: 330, width: "23%", minHeight: "220px" }}
          onClick={() => {
            // this.routeToPage(setting.url, setting.navigateTo, setting);
          }}
        >
          <div className="row">
            <DKIcon src={setting.icon} className="" />
            <DKLabel text={setting.iconTitle} className="ml-s fw-m fs-m" />
            {setting.hasInfo && setting.url === "CONNECT_PAYPAL" && (
              <div
                className="d-flex ellipsis"
                style={{
                  alignItems: "center",
                  width: "max-content",
                }}
              >
                <div className="d-flex pl-s fw-m">
                    <DKTooltipWrapper
                        content={this.state.isPaypalConnected ? "Paypal is configured" : "Paypal is not configured"}
                        tooltipClassName="warning-tooltip-prop"
                    >
                        <DKStatus color={this.state.isPaypalConnected ? "bg-green" : "bg-gray"} />
                    </DKTooltipWrapper>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <DKLabel text={setting.title} className="fw-m mt-m fs-m" />
            {setting.hasInfo && setting.url === "PAYMENT_OPTIONS" && (
                <div className="ml-m mt-m">
                    <DKCheckMark
                        isSelected={this.state.isCheckHqPaymentEnabled}
                        color="bg-app"
                        onClick={() => {
                            const tenantData = TenantManager.getPeopleTenantData();
                            if (tenantData) {
                                tenantData.tenant.isCheckhqOffCyclePaymentEnabled = !this.state.isCheckHqPaymentEnabled;
                                TenantManager.setPeopleTenantData(tenantData);
                            }

                            this.setState({
                                isCheckHqPaymentEnabled: !this.state.isCheckHqPaymentEnabled
                            });
                        }}
                    />
                </div>
            )}
          </div>
          <DKLabel
            text={setting.description}
            className="text-dark-gray mt-xs"
            style={{ height: 40 }}
          />
          {setting.hasInfo && setting.url === "CONNECT_PAYPAL" && (
                <div className="mt-s">
                    <span className="fw-b">Note: </span>
                    <span>
                        Paypal charges fee for payouts, for more details&nbsp;
                        <a href="https://www.paypal.com/us/webapps/mpp/merchant-fees#maxfeecap" target="_blank">click here</a>
                    </span>
                </div>
            )}
            {setting.buttonTitle && (
                <div
                    className="row-reverse"
                    style={{ justifyContent: "space-between", marginTop: "auto" }}
                >
                    <div>
                    <DKButton
                        title={setting.buttonTitle}
                        className="align-self-end bg-gray1 border-m mt-xl"
                        icon={DKIcons.ic_arrow_right2}
                        isReverse={true}
                        onClick={() => {
                            this.routeToPage(
                                setting.url,
                                setting.navigateTo,
                                setting.redirectTo
                            );
                        }}
                    />
                    </div>
                </div>
            )}
        </div>
      </>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  getHelpSection() {
    return (
      <div className="column bg-deskera-secondary p-r border-radius-m border-m mt-m banner-holder">
        <DKLabel text='<span style="font-size:20px">🙋‍♂️</span> Need help getting started? Learn more about Expense setting options.' />

        <div className="row mt-r ml-l">
          <DKButton
            className="border-m bg-white"
            title="Read"
            onClick={AppManager.showComingSoonWarning}
          />
          <DKButton
            className="border-m ml-r bg-white"
            title="Watch"
            onClick={AppManager.showComingSoonWarning}
          />
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  getFooter() {
    return (
      <div className="mt-xl parent-width mb-xxl">
        <DKLabel className="text-align-center fw-m" text="Contact us" />
        <DKLabel
          className="text-align-center mt-s text-gray"
          text="If you have any other query or problem please feel free to contact us"
        />
        <div className="cursor-hand" onClick={this.showFeedbackPopup}>
          <DKLabel
            className="text-align-center text-blue mt-r"
            text="<u>care@deskera.com</u>"
          />
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  showFeedbackPopup = () => {
    this.setState({ needFeedbackPopup: true });
  };
  hideFeedbackPopup = () => {
    this.setState({ needFeedbackPopup: false });
  };

  hideSelectOrgPopup = () => {
    this.setState({ showSelectOrgPopUp: false });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
}
