import { Component } from "react";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import { TableManger, TABLES } from "../../managers/TableManager";
import ExpenseService from "../../services/ExpenseService";
import DataGridHolderPayroll from "../common/DataGridHolderPayroll";
import { ExpenseGroupUserColumnConfig, parseColumnConfig, parseVisibleColumnConfig } from "../../services/Config";
import {
  DKButton,
  showToast,
  TOAST_TYPE,
  showAlert,
} from "deskera-ui-library";
import ic_arrow_back from "../../assets/icons/ic_arrow_back.svg";
import Popup from "../common/Popup";
import AddGroupUser from "./AddGroupUsers";
import ExpenseManager from "../../managers/ExpenseManager";
import Moveuser from "./MoveUser";
import { CONSTANTS } from "../../utils/Constants";
import { showAlertEmployeeExpense } from "../../managers/common/CommonUtil";
export default class GroupUser extends Component {

    groupData = {};
    filters = [];
    currentPage = 1;
    totalPageCount = 1;
    groupId;
    groupName;
    isPopupVisible = false;
    moveUserDetail = {
        isVisible :false,
        groupId: null
    }
    constructor(props) {
        super(props);
        if (this.props.match.params.id && this.props.match.params.name) {
            this.groupId = this.props.match.params.id;
            this.groupName = this.props.match.params.name;
            this.moveUserDetail['groupId'] = this.groupId;
        }
        this.getUserByGroup();
    }

    componentDidMount() {
        RouteManager.setPresenter(this);
        const initial = {
            columnData: [],
            rowData: [],
            originalData: [],
            filter: []
        };
        this.groupData = initial;
        this.updateState();
        let allUsers = ExpenseManager.getUsers();
        if(!allUsers) {
            ExpenseManager.getUserFromApi()
        }
    }
    updateState() {
        this.setState({
            ...this.state
        })
    }
    getUserByGroup() {
        ExpenseService.getUserByGroup(this.groupId).then(res => {
            let data = ExpenseGroupUserColumnConfig(res);
            const columnData = parseVisibleColumnConfig(parseColumnConfig(TableManger.getTableVisibleColumns(TABLES.PEOPLE_EXPENSE_GROUP_USER), TABLES.PEOPLE_EXPENSE_GROUP_USER));
            const initial = {
                columnData: columnData,
                rowData: data,
                originalData: data,
                filter: []
            };
            this.groupData = initial;
            this.updateState()
        }, error => {
            console.log(error);
        })
    }
    onClosePopup(data) {
        if (data) {

            this.getUserByGroup();
            ExpenseManager.getUserFromApi()
        }
        this.isPopupVisible = false;
        this.updateState();
    }
    onCloseMoveUserPopup(data) {
        this.isPopupVisible = false;
        this.moveUserDetail.isVisible = false;
        if(data) {
            this.getUserByGroup();
        }
        this.moveUserDetail.data = null;

        this.updateState();
    }
    onRowDelete(data) {
        const cannotDeleteOrUpdate = [
          {
            title: "Cancel",
            className: "bg-gray1 border-m",
          },
        ];
        if (
          data?.rowData?.data &&
          (data.rowData.data.role === CONSTANTS.ADMIN.toLowerCase() ||
            data.rowData.data.role === CONSTANTS.MANAGER.toLowerCase())
        ) {
          showAlert(
            "Sorry!!",
            "Admin/Manager role user cannot be deleted",
            cannotDeleteOrUpdate
          );
          return;
        }
        if(data?.rowData?.data && data.rowData.data.id) {
            let param = {
            groupId: this.groupId,
            role: data.rowData.data.role,
            userId: data.rowData.data.id
        }
            ExpenseService.deleteUser(data.rowData.data.id,param).then(res=>{
                showToast("Member has been removed from the group", TOAST_TYPE.SUCCESS);
                this.getUserByGroup();
                ExpenseManager.getUserFromApi()
                this.updateState();
            },error=>{
                if(error && error.message) {
                    showToast(error.message, TOAST_TYPE.FAILURE);
                } else {
                    showToast("Member not able to remove from group", TOAST_TYPE.FAILURE);
                }

            })
        }

    }
    moveUser=(data)=> {   
        if(data.rowData.data) {
            this.isPopupVisible = false;
            this.moveUserDetail.isVisible = true;
            this.moveUserDetail.data = data.rowData.data;
            const iamUserIds = [data.rowData.data.id] || [];
            ExpenseService.isUserInExpenseApproval(iamUserIds).then(res => {
                
                this.isPopupVisible = false;
                this.moveUserDetail.isVisible = true;
                this.moveUserDetail.data = data.rowData.data;
                this.updateState();
            }, error => {
                showAlertEmployeeExpense('move')

            })            
        }        
    }
    changeRole(data,value) {
        let isManager = value;
        let users = data.map(d=>{

            let obj = {
                groupId: d.data.group.id,
                role: value ? "employee":"manager",
                userId: d.data.id
            }
            return obj;
        })
        ExpenseService.changeRole(users,isManager).then(res => {
            this.getUserByGroup();
            ExpenseManager.getUserFromApi()
            this.updateState();
            showToast('Role changed',TOAST_TYPE.SUCCESS)
        },error=>{
            if(error && error.message) {
                showToast(error.message,TOAST_TYPE.FAILURE)
            }
        })

    }

    render() {
        return (
            <>
            {this.isPopupVisible && <Popup popupClass={" popup-overflow "}>
                <AddGroupUser {...this.props} data={this.groupId}  onClose={(data) => {this.onClosePopup(data) } } />
            </Popup>}
            {
                this.moveUserDetail.isVisible && <Popup popupClass={" popup-overflow "}>
                    <Moveuser data={this.moveUserDetail} onClose={(data) => {this.onCloseMoveUserPopup(data) } } />
                </Popup>
            }
            <div className="column parent-height" >
                <DKButton icon={ic_arrow_back} title="Back to List" className="pl-0 cursor-hand" onClick={() => RouteManager.navigateToPage(PAGE_ROUTES.EXPENSE.EXPENSE_ADMIN_SETTING)} />
                <div className="flex-1 position-relative" style={{ minHeight: "200px" }}>
                    <DataGridHolderPayroll
                        tableName={TABLES.PEOPLE_EXPENSE_GROUP_USER}
                        displayTableName={this.groupName}
                        allowColumnEdit={false}
                        allowColumnDelete={false}
                        allowColumnShift={false}
                        allowSearch={false}
                        allowFilter={false}
                        allowColumnAdd={false}
                        allowRowAdd={false}
                        refresh={false}
                        allowDataExport={false}
                        allowRowEdit={true}
                        showContextEdit={false}
                        isActionEnabled={true}
                        contextMenu={true}
                        isMenuVisible={true}
                        gridData={this.groupData}
                        filter={this.filters}
                        currentPage={this.currentPage}
                        totalPageCount={this.totalPageCount}
                        onFilter={(dataList, queries) => {
                            // onFilter(dataList, queries);
                        }}
                        onRowDelete={(data) => {
                            this.onRowDelete(data);
                        }}
                        onRowEdit={(data) => {
                            this.moveUser(data);
                        }}
                        addButtonConfig={[{
                            title: "+ Add Member",
                            className: "bg-app text-white ml-s",
                            onClick: () => {
                                this.isPopupVisible = true;
                                this.updateState()
                            }
                        }]}
                            addMenuButton={[{
                                title: "Assign Manager",
                                className: " bg-gray1 border-m mr-r ml-s",
                                hide: false,
                                onSelectionTitle:{
                                    title1: "Assign Manager",
                                    title2: "Unassign Manager"
                                },
                                onClick: (data,value) => {
                                    this.changeRole(data,value)
                                }
                            }]}
                        onCancelContext={
                            ()=>{
                                this.getUserByGroup();
                                ExpenseManager.getUserFromApi()
                                this.updateState();
                            }
                        }
                        onPaginationClick={(requestedPageNumber) => {
                            // fetchGroup({
                            //     ...ExpenseService.apiConfig,
                            //     params: {
                            //         limit: 20,
                            //         page: requestedPageNumber - 1
                            //     }
                            // })
                        }}
                        onRowOpenBtnHidden={true}
                    />
                </div>
            </div>
            </>
        )
    }
}