import {
    DKIcons
} from "deskera-ui-library";
import {
  COLUMN_CODES,
  FORMAT,
} from "../utils/Constants";
import { COLUMN_CODE, TABLES } from "../managers/TableManager";
import Utility, { deepClone } from "../utils/Utility";
import { isIndiaCompliance, isUSCompliance } from "../managers/common/CommonUtil";
import { EXPENSE_CONSTANTS } from "./ExpenseClaimListService";
import ic_check_mark from "../assets/icons/ic_check_mark.png";
import ic_close from "../assets/icons/ic_close.png";
import ic_copy from "../assets/icons/ic_copy.png";
import ic_printer from "../assets/icons/ic_printer.png";
import ic_save from "../assets/icons/ic_save.png";
import ic_delete from "../assets/icons/ic_delete.png";
import ic_info_blue from "../assets/icons/ic_info_blue.svg";
import { DISPLAY_PAYMENT_METHOD } from "../components/userManagement/UserManagementConstants";
import { EXPENSE_CLAIM_STATUS, EXPENSE_CLAIM_VIEW_STATUS } from "../utils/ExpenseCommon";
import { TENANT, TenantService } from "./TenantMangerService";
import moment from "moment";

export function ExpenseSyncRevertColumnConfig(expenses, isSync) {
  let rowData = [];
  if (expenses && expenses.length > 0) {
    expenses.forEach((expense) => {
      const row = {
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.EXPENSE_ID]: expense.code,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.CATEGORY]: expense.category,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.DATE]: Utility.formatDate(
          expense.spendDate,
          FORMAT.YYYYMMDD
        ),
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.APPLICANT]: expense.userName,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.EXPENSE_GROUP]:
          expense.groupName,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.MERCHANT]: expense.merchant,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.TOTAL_AMOUNT]:
          expense.totalAmount,
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.DATE_OF_JE]: new Date(),
        [COLUMN_CODE.PEOPLE_EXPENSE_SYNC_REVERT_JE.DATE_OF_REVERSAL_JE]: !isSync
          ? new Date()
          : "",
        ["data"]: expense,
        ["id"]: expense.id,
      };
      rowData.push(row);
    });
  }
  return rowData;
}

export function parseExpenseColumnConfig(columnData, isSync) {
  columnData.forEach((column) => {
    switch (column.columnCode) {
      case COLUMN_CODES.DATE_OF_JE:
        column.editable = isSync;
        break;
      case COLUMN_CODES.DATE_OF_REVERSAL_JE:
        column.editable = !isSync;
        column.uiVisible = !isSync;
        column.hidden = isSync;
        break;
    }
  });
  return columnData;
}

export function parseVisibleColumnConfig(columnData) {
  columnData = columnData.filter((column) => !column.hidden);
  columnData.forEach((column) => {
    column["key"] = column.columnCode;
  });
  return columnData;
}

export function parseColumnConfig(columnData, tableName, viewConfig) {
  let isContractor = false;
  if (viewConfig) {
    isContractor = viewConfig.isContractor;
  }
  columnData.forEach((column) => {
    column["key"] = column.columnCode;
    if (isIndiaCompliance()) {
      if (column.columnCode === "compensation") {
        column.uiVisible = false;
        column.hidden = true;
        column.name = "Basic/Salary";
        column.type = "number";
      } else if (column.columnCode === "ctc") {
        column.uiVisible = true;
        column.hidden = false;
        column.name = "CTC";
        column.type = "number";
      }
    } else {
      if (column.columnCode === "compensation") {
        column.uiVisible = true;
        column.hidden = false;
        column.name = "Basic/Salary";
        column.type = "number";
      } else if (column.columnCode === "ctc") {
        column.uiVisible = false;
        column.hidden = true;
        column.name = "CTC";
        column.type = "number";
      }
    }

    switch (column.columnCode) {
      case "pf":
      case "esi":
      case "taxexempt":
        column.uiVisible = isIndiaCompliance() ? true : false;
        column.hidden = isIndiaCompliance() ? false : true;
        break;
      case "deductionType":
        column.uiVisible = isUSCompliance() ? true : false;
        column.hidden = isUSCompliance() ? false : true;
        break;
      case "overtime":
        column.name = "Overtime (OT x Hrs x Rate = Amount)";
        column.width = 300;
        break;
      case "uniqueId":
        if (isIndiaCompliance()) {
          column.uiVisible = false;
          column.hidden = true;
        }
        break;
      case "filePath":
      case "updatedFilePath":
        if (column.name == "Original File" || column.name == "Download") {
          column.hidden = true;
          column.uiVisible = false;
        }
        break;
      case "totalMinutes":
      case "entryOffsetMinutes":
      case "exitOffsetMinutes":
      case "clockIn":
        column.editable = false;
        break;
      case "designation":
        column.name = "Job Title";
        break;
      case "lop":
        column.name = "Unpaid Days";
        break;
      case "payDate":
        column.width = 180;
        break;
      case "status":
         if (tableName === TABLES.PEOPLE_EXPENSE_CLAIMS) {
          column.allowFilter = false;
          column.width = 120;
        }
        break;
      case "days":
      case "period":
      case "grossPay":
      case "taxes":
      case "netPay":
        column.hidden = isContractor;
        break;
      case "code":
      case "spendDate":
      case "userName":
      case "merchant":
      case "groupName":
      case "totalAmount":
        if (tableName === TABLES.PEOPLE_EXPENSE_CLAIMS) {
          column.allowFilter = false;
        }
        break;
      case "category":
        if (tableName === TABLES.PEOPLE_EXPENSE_CLAIMS) {
          column.type = "select";
          if (Array.isArray(viewConfig.categories)) {
            column.options = [
              { id: "", name: "All" },
              ...viewConfig.categories,
            ];
          }
        }
        break;
      case COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP:
        column.allowFilter = true;
        column.required = true;
        column.editable = true;
        break;
      case COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.CATEGORY_ID:
        column.allowAddOption = false;
        break;
      case COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.TAX_CODE:
        column.allowAddOption = false;
        break;
    }
  });
  return columnData;
}

export function ExpenseGroupUserColumnConfig(expenses) {
  let rowData = [];
  if (expenses && expenses.length > 0) {
    expenses.forEach((expense) => {
      const row = {
        [COLUMN_CODE.PEOPLE_EXPENSE_GROUP_USER
          .NAME]: `${expense.firstName} ${expense.lastName}`,
        [COLUMN_CODE.PEOPLE_EXPENSE_GROUP_USER.ROLE]:
          expense.role == "admin"
            ? "Admin"
            : expense.role == "employee"
            ? "Member"
            : "Manager",
        ["data"]: expense,
      };
      rowData.push(row);
    });
  }
  return rowData;
}

export function ExpenseCategoryColumnConfig(expenses) {
  let rowData = [];
  if (expenses && expenses.length > 0) {
    expenses.forEach((expense) => {
      const row = {
        [COLUMN_CODE.PEOPLE_EXPENSE_CATEGORY.NAME]: expense.name,
        [COLUMN_CODE.PEOPLE_EXPENSE_CATEGORY.GROUP_NAME]: expense.groups
          ? expense.groups.map((res) => res.name).join(", ")
          : "",
        ["data"]: expense,
      };
      rowData.push(row);
    });
  }
  return rowData;
}

export function ExpenseGroupColumnConfig(expenses) {
  let rowData = [];
  if (expenses && expenses.length > 0) {
    expenses.forEach((expense) => {
      const row = {
        [COLUMN_CODE.PEOPLE_EXPENSE_GROUP.NAME]: expense.name,
        [COLUMN_CODE.PEOPLE_EXPENSE_GROUP.MEMBER_COUNT]: expense.memberCount
          ? `${expense.memberCount} ${
              expense.memberCount && expense.memberCount > 1
                ? "Members"
                : "Member"
            } `
          : "<span class='text-red'>0 Members</span>",
        ["data"]: expense,
      };
      rowData.push(row);
    });
  }
  return rowData;
}

export function ExpenseClaimsColumnConfig(
  expenses,
  onContextMenuAction,
  isAdmin,
  userId
) {
  let rowData = [];
  if (expenses && expenses.length > 0) {
    expenses.forEach((expense) => {
      const exp = deepClone(expense);
            const multilevelApproval = getApprovalStatus(exp['approvalStages']);
            const status = (multilevelApproval && multilevelApproval.status && expense.status === EXPENSE_CLAIM_STATUS.PENDING) ? multilevelApproval.status : expense.status
      const row = {
        ["id"]: expense.id,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.EXPENSE_ID]: expense.code,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.CATEGORY]: expense.category,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.DATE]: Utility.formatDate(
          expense.spendDate,
          FORMAT.YYYYMMDD
        ),
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.APPLICANT]: expense.userName,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.EXPENSE_GROUP]: expense.groupName,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.MERCHANT]: expense.merchant,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.TOTAL_AMOUNT]: expense.totalAmount,
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.STATUS]: getExpeseStatus(expense),
        [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP]: expense.expenseGroup,
        ["rowContextMenu"]: getContextMenuForExpenseGrid(
          expense,
          onContextMenuAction,
          isAdmin,
          userId
        ),
        ["nonEditableColumns"]:
          expense.status == EXPENSE_CONSTANTS.DRAFT &&
          (userId === expense.userId || isAdmin)
            ? []
            : [COLUMN_CODE.PEOPLE_EXPENSE_CLAIMS.GROUP],
        ["data"]: expense,
        ['statusValue']: status,
        ['isMultilevelApproval']: expense['approvalStages'] && multilevelApproval ? true : false,
      };
      rowData.push(row);
    });
  }
  return rowData;
}

export function getFileName(url) {
  return url.substring(url.indexOf("_") + 1, url.length);
}

export function insertRowIntoMultiClaim(rowData) {
  const row = {
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.EXPENSE_ID]: null,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.DATE]: new Date(),
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.MERCHANT]: "",
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.TAX_AMOUNT]: 0,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.TAX_CODE]: null,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.TOTAL_AMOUNT]: 0,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.OTHER_DETAILS]: "",
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.CATEGORY_ID]: null,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.ATTACHMENTS]: null,
    [COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.DESCRIPTION]: "",
    invalidFields: [],
  };
  rowData.push(row);
  return rowData;
}
export function getApprovalStatus(approvalStages) {
  if(!approvalStages) {
      return
  }
  const iamUserId = TenantService.get(TENANT.IAM_USER_ID);
  // find ( level st not rejected && approver) if yes save(level,apprObj) else false
  let approvalDetail
  // here need to sort approvalStages
  approvalStages?.sort(function(a, b) {
    return parseFloat(a.level) - parseFloat(b.level);
  });
  approvalStages?.every(stage=>{
      if(stage.levelStatus === EXPENSE_CLAIM_STATUS.REJECTED) {// if rejected for level then no need to proceed. 
          return false
      }
      approvalDetail = stage.approvers.find(approver=> approver.id == iamUserId);
      if(approvalDetail) {
          approvalDetail['status'] = Utility.isEmpty(approvalDetail['status']) ?EXPENSE_CLAIM_STATUS.PENDING : approvalDetail['status'];
          return false;
      }
      if(stage.levelStatus === EXPENSE_CLAIM_STATUS.PENDING && !approvalDetail) { // if pending for lower level then no need to proceed. 
          return false
      }
      return true;
  })
  return approvalDetail;

}
export function getContextMenuForExpenseGrid(exp, onContextMenuAction, isAdmin, userId) {
  const contextMenu = [];
  // const { tenant } = TenantManager.getPeopleTenantData();
  let expense = deepClone(exp);
  let multilevelApproval = getApprovalStatus(expense['approvalStages'])
  
  let status = (multilevelApproval && multilevelApproval.status && expense.status === EXPENSE_CLAIM_STATUS.PENDING) ? multilevelApproval.status : expense.status
  const approve = {
      title: "Approve",
      icon: ic_check_mark,
      onClick: (data) => {
          onContextMenuAction(EXPENSE_CONSTANTS.APPROVE, data);
      }
  }
  const reject = {
      title: "Reject",
      icon: ic_close,
      onClick: (data) => {
          onContextMenuAction(EXPENSE_CONSTANTS.REJECT, data);
      }
  }
  const duplicate = {
      title: "Duplicate",
      icon: ic_copy,
      onClick: (data) => {
          onContextMenuAction(EXPENSE_CONSTANTS.DUPLICATE, data);
      }
  }
  switch (status) {
      case "PENDING":
        if(expense['approvalStages']) {
            if(multilevelApproval) {
                contextMenu.push(approve);
                contextMenu.push(reject);
            }
          } else {
              if (isAdmin) {
                  contextMenu.push(approve);
                  contextMenu.push(reject);
              }
          }
          if((isAdmin || userId === expense.userId) || multilevelApproval) {
            contextMenu.push(duplicate);
          }
          break;
      case "DRAFT":
          contextMenu.push(
              {
                  icon: DKIcons.ic_edit,
                  title: "Edit",
                  className:'p-0 text-app fw-m text-underline',
                  onClick: (data) => {
                      onContextMenuAction(EXPENSE_CONSTANTS.EDIT, data);
                  }
              }
          );
          if (userId === expense.userId) {
              contextMenu.push(
                  {
                      title: "Submit",
                      icon: ic_save,
                      onClick: (data) => {
                          onContextMenuAction(EXPENSE_CONSTANTS.SUBMITTED, data);
                      }
                  }
              );
          }
          
          if((isAdmin || userId === expense.userId) || multilevelApproval) {
            contextMenu.push(duplicate);
          }
          
          break;
      }

      contextMenu.push(
          {
              title: "Delete",
              icon: ic_delete,
              onClick: (data) => {
                  onContextMenuAction(EXPENSE_CONSTANTS.DELETE, data);
              }
          }
      );

      contextMenu.push(
          {
              title: "Print",
              icon: ic_printer,
              onClick: (data) => {
                  onContextMenuAction(EXPENSE_CONSTANTS.PRINT, data);
              }
          }
      );

  return contextMenu;
}

export function UsersColumnConfig(users) {
    let rowData = [];
    if (users && users.length > 0) {
        users.forEach((user) => {
            const row = {
                iamUserId: user.iamUserId,
                userId: user.id,
                [COLUMN_CODE.PEOPLE_EXPENSE_USER_MANAGEMENT.NAME]: `${user.firstName} ${user.lastName}`,
                [COLUMN_CODE.PEOPLE_EXPENSE_USER_MANAGEMENT.PAYMENT_METHOD]: DISPLAY_PAYMENT_METHOD[user.expensePayoutMethod] || DISPLAY_PAYMENT_METHOD["PAYPAL_EMAIL"],
                [COLUMN_CODE.PEOPLE_EXPENSE_USER_MANAGEMENT.PAYPAL_EMAIL]: user.paypalEmail
            };

            rowData.push(row);
        });
    }

    return rowData;
}

export function PayoutLogsColumnConfig(payouts) {
    let rowData = [];
    if (payouts && payouts.length > 0) {
        payouts.forEach((payout) => {
            const row = {
                [COLUMN_CODE.PEOPLE_EXPENSE_PAYOUT_LOGS.EXPENSE_ID]: payout.expenseId,
                [COLUMN_CODE.PEOPLE_EXPENSE_PAYOUT_LOGS.DATE]: payout.date,
                [COLUMN_CODE.PEOPLE_EXPENSE_PAYOUT_LOGS.APPLICANT]: payout.applicant,
                [COLUMN_CODE.PEOPLE_EXPENSE_PAYOUT_LOGS.AMOUNT]: payout.amount,
                [COLUMN_CODE.PEOPLE_EXPENSE_PAYOUT_LOGS.STATUS]: payout.status
            };

            rowData.push(row);
        });
    }

    return rowData;
}

export function getExpeseStatus(exp) {
    
  let expenseApprovalStatus = `<b>Approval Levels</b>`;
  let expense = deepClone(exp);
  
  let approvalLevels = expense['approvalStages'];
  approvalLevels?.forEach((level,index)=>{
      let statusStyle = {} ;
      statusStyle = getStatusStyle(level.levelStatus , statusStyle);
      expenseApprovalStatus += `<p class='m-0'><br/><b>${level.label ? level.label :"Level "+index+1}:</b><span class='${statusStyle['color']} pl-s'><b>${EXPENSE_CLAIM_VIEW_STATUS[level.levelStatus] ? EXPENSE_CLAIM_VIEW_STATUS[level.levelStatus] : EXPENSE_CLAIM_VIEW_STATUS[EXPENSE_CLAIM_STATUS.PENDING] }</b></span></p>`;
      expenseApprovalStatus += `<table><ul>`;
      level.approvers.forEach(approval=>{
          let approvalStyle = {};
          approvalStyle = getStatusStyle(approval.status,approvalStyle)
          expenseApprovalStatus += `<tr style='white-space: nowrap;'><td style='white-space: nowrap;'><li class='ml-m' style='white-space: nowrap;'>${!Utility.isEmpty(approval.name)?approval.name:""}</li></td><td class='${approvalStyle['color']}' style='white-space: nowrap;'> - ${EXPENSE_CLAIM_VIEW_STATUS[approval.status]}</td><td style='white-space: nowrap;'>${approval.approvedAt? " at":""} ${approval.approvedAt ? Utility.formatDate(moment(approval.approvedAt).format(FORMAT.YYYYMMDD), FORMAT.YYYYMMDD):""}</td></tr>`;
      })
      expenseApprovalStatus += '</table></ul>';
  }) 
  if(approvalLevels && approvalLevels.length != 0) {
      return `<div class="d-flex align-items-center">${expense.status}<span class="ml-s info_error_icon" data-tip="${expenseApprovalStatus}"><img class="ic-s" style="margin-top:1px;" src= ${ic_info_blue} /></span></div>`
  } else {
      return expense.status
  }
}
export function getStatusStyle(status,statusStyle={}) {
  switch(status) {
      case EXPENSE_CLAIM_STATUS.APPROVED:
          statusStyle['color'] = "text-green";
          break;
      case EXPENSE_CLAIM_STATUS.PENDING:
          statusStyle['color'] = "text-blue";
          break;
      case EXPENSE_CLAIM_STATUS.REJECTED:
          statusStyle['color'] = "text-red";
          break;
      default:
          statusStyle['color'] = "text-blue";
          
  }
  return statusStyle;
}