import React, { Component, Fragment } from "react";
import AppManager from "../../managers/AppManager";
import UserManager from "../../managers/UserManager";
import SideBarService from "../../services/Sidebar";
import Tenant from "../../services/Tenant";
import TenantManager from "../../managers/TenantManager";
import { showToast, TOAST_TYPE, DKSidebar } from "deskera-ui-library";
import IAM from "../../services/Iam";
import {
  ADMIN,
  ADMIN_EMPLOYEE,
  CONTRACTOR,
  EMPLOYEE,
  NAVIGATIONS,
} from "../../managers/common/RouteAuth";
import { TenantService, TENANT } from "../../services/TenantMangerService";
import {
  isMobileWebView,
  isViewportLarge,
} from "../../utils/ViewportSizeUtils";
import { LeftMenuUpdate } from "../../services/LeftMenuService";
import { withTranslation } from "react-i18next";

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    window.addEventListener("onSideBarLoad", this.handlerSidebarLoad);
    const currentPath = props.location?.pathname;
    this.state = {
      isMenuExpanded: isViewportLarge(),
      selectedTenantDetails: {
        tenantName: UserManager.getUserTenantName(),
        tenantId: UserManager.getUserTenantID(),
      },
      isSideBarLoad: false,
      tenantList: [],
      isLeftMenuUpdate: false,
      showAddTenantPopup: false,
      isAdmin: TenantService.get(TENANT.IS_ADMIN),
      isNavigationUpdated: false
    };

    this.navigationRoutes = this.getActiveNavigationRoutes(
      TenantService.get(TENANT.IS_ADMIN)
    );
    this.setState({
      isNavigationUpdated: !this.state.isNavigationUpdated
    })
  }

  isRouteEnable(r, countryCode, isAdmin) {
    let isContractor = TenantService.get(TENANT.IS_CONTRACTOR);
    return (
      (r.country.includes("-1") || r.country.includes(countryCode)) &&
      ((r.accessTo === ADMIN_EMPLOYEE && !isContractor) ||
        (isAdmin
          ? r.accessTo === ADMIN
          : isContractor
          ? r.accessTo === CONTRACTOR
          : r.accessTo === EMPLOYEE))
    );
  }

  getActiveNavigationRoutes(isAdmin) {
    const countryCode = TenantService.get(TENANT.COUNTRY_CODE);
    const activeNavigationRoutes = [];
    let NAV = this.getFilteredNavigations(NAVIGATIONS);
    NAV.forEach((r) => {
      if (!r.hide && this.isRouteEnable(r, countryCode, isAdmin)) {
        r.name = this.props.t(r.name);
        activeNavigationRoutes.push(r);
        if (r.subItems && r.subItems.length > 0) {
          const subItems = [];
          r.subItems.forEach((sr) => {
            if (!sr.hide && this.isRouteEnable(sr, countryCode, isAdmin)) {
              sr.name = this.props.t(sr.name);
              subItems.push(sr);
            }
          });

          r.subItems = subItems;
        }
      }
    });

    return activeNavigationRoutes;
  }

  getFilteredNavigations(Routes) {
    Routes.forEach((r) => {
        switch (r.id) {
            case "EXPENSE_INTEGRATION_SETTINGS":
                if (TenantService.get(TENANT.ERP_INTEGATION_SETTING)) {
                    r.hide = isMobileWebView();
                } else {
                    r.hide = true;
                }
                break;
            case "USER_MANAGEMENT":
            case "PAYOUT_LOGS":
                if (TenantService.get(TENANT.IS_PAYPAL_CONNECTED)) {
                    r.hide = isMobileWebView();
                } else {
                    r.hide = true;
                }
                break;
            default:
                break;
        }
    });

    return Routes;
  }

  componentDidMount() {
    Tenant.getAllTenants()
      .then((res) => {
        this.setState({
          tenantList: TenantManager.getAllTenants(),
        });
      })
      .catch((err) => {
        console.log(err);
      });
    window.addEventListener("onSideBarChange", this.handleSidebarToggle);
    window.addEventListener("resize", this.handleSidebarOnResize);
    LeftMenuUpdate.getMessage().subscribe((response) => {
      this.setState({
        isLeftMenuUpdate: !this.state.isLeftMenuUpdate,
      });
      this.navigationRoutes = this.getActiveNavigationRoutes(
        TenantService.get(TENANT.IS_ADMIN)
      );
      this.setState({
        isNavigationUpdated: !this.state.isNavigationUpdated,
      });
    });
  }

  handlerSidebarLoad = () => {
    this.setState({ isSideBarLoad: true });
  };

  componentWillUnmount() {
    window.removeEventListener("onSideBarLoad", this.handlerSidebarLoad);
    window.removeEventListener("onSideBarChange", this.handleSidebarToggle);
    window.removeEventListener("resize", this.handleSidebarOnResize);
  }

  handleSidebarOnResize() {
    SideBarService.setIsSideBarExpanded();
    SideBarService.triggerSidebarChangeEvent();
  }

  handleSidebarToggle = () => {
    this.setState({ isMenuExpanded: SideBarService.isSideBarExpanded });
  };

  onSaveNewTenantTapped = (newTenantName) => {
    Tenant.getOrgWithoutSetup({
      tenantName: newTenantName,
      isBookkeeper: false,
      refreshToken: "refreshToken",
    }).then((res) => {
      IAM.switchTenant({ tenantId: res.tenantId }).then((res) => {
        window.location.reload();
      });
    });
  };

  onSelectTenant = (newSelectedTenantDetails) => {
    if (
      newSelectedTenantDetails.tenantId !==
      this.state.selectedTenantDetails.tenantId
    ) {
      IAM.switchTenant({ tenantId: newSelectedTenantDetails.tenantId }).then(
        (res) => {
          AppManager.refreshApp();
        },
        (err) => {}
      );
    } else {
      showToast("Current organisation", TOAST_TYPE.SUCCESS);
    }
  };

  render() {
    return (
      <Fragment>
        <div
          className={`transparent-background parent-height parent-width position-absolute ${
            this.state.isMenuExpanded
              ? "display-only-mobile z-index-5"
              : "display-none z-index-0"
          }`}
          onClick={() => SideBarService.toggleSideBar()}
        ></div>
        <DKSidebar
          className="z-index-6"
          collapsedWidth={0}
          expandedWidth={230}
          allowTenantSelection={!isMobileWebView()}
          menuItemList={this.navigationRoutes}
          tenantList={this.state.tenantList}
          isMenuExpanded={this.state.isMenuExpanded}
          selectedTenantDetails={this.state.selectedTenantDetails}
          onAddNewTenantTapped={(data) =>
            this.setState({ showAddTenantPopup: true })
          }
          onTenantSelect={(newTenantDetails) =>
            this.onSelectTenant(newTenantDetails)
          }
          onExpandCollapse={() => SideBarService.toggleSideBar()}
          allowAddTenant={false}
        />
        {/* {this.state.showAddTenantPopup && (
          <Popup popupWindowStyles={{ overflow: "visible" }}>
            <AddNewTenant
              onClose={() => this.setState({ showAddTenantPopup: false })}
              onSave={(data) => this.setState({ showAddTenantPopup: false })}
            />
          </Popup>
        )} */}
      </Fragment>
    );
  }
}

export default withTranslation()(LeftMenu);
