import React, { Component } from "react";
import "./css/Common.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import LeftMenu from "./components/menu/LeftMenu";
import {
    showAlert,
    removeLoader,
    showLoader,
    DKButton,
} from "deskera-ui-library";
import UserManager from "./managers/UserManager";
import IAM from "./services/Iam";
import MixPanelManager from "./managers/common/MIxpanelManager";
import RouteManager from "./managers/RouteManager";
import InitialApiManager from "./managers/InitialApiManager";
import AppManager from "./managers/AppManager";
import ApiManager from "./managers/ApiManager";
import Tenant from "./services/Tenant";
import TenantManager from "./managers/TenantManager";
import DomainManager from "./managers/DomainManager";
import Table from "./services/Table";
import {
    ADMIN,
    ADMIN_EMPLOYEE,
    CONTRACTOR,
    EMPLOYEE,
    ROUTES,
} from "./managers/common/RouteAuth";
import { TENANT, TenantService } from "./services/TenantMangerService";
import ApiConstants from "./utils/ApiConstants";
import { LeftMenuUpdate } from "./services/LeftMenuService";
import { isMobileWebView } from "./utils/ViewportSizeUtils";
import Utility, { deepClone } from "./utils/Utility";
import { Countries, COUNTRY } from "./utils/CountryConstant";
import CompanyDetailService from "./services/CompayDetailService";
import TagManager from "react-gtm-module";
import UserService from "./services/UserService";
import TopMenu from "./components/common/TopMenu";
import PaypalService from "./services/PaypalService";
import { HIDE_CHAT_BUBBLE_CLASS } from "./utils/Constants";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_TOKEN
};
TagManager.initialize(tagManagerArgs);

class App extends Component {
    constructor(props) {
        super(props);
        if (!isMobileWebView()) {
            this.load_cookie_pref();
        }
        this.state = {
            didInitialApiResponseReceived: false,
            isOrgAvailable: null,
            path: window.location.pathname,
            needLogger: false,
            isOrgSetupCompleted: false,
            isAdmin: false,
            activeRoutes: [],
        };
    }

    load_cookie_pref() {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://tracker.deskera.com/cookie-consent.min.js";
        document.head.appendChild(script);

        script.onload = () => {
            let script_custom = document.createElement("script");
            script_custom.textContent = 'cookieconsent.run({ notice_banner_type: "simple", consent_type: "express",palette: "light",language: "en", cookies_policy_url: "https://www.deskera.com/cookie-policy"});';
            document.head.appendChild(script_custom);
        };
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    componentDidMount() {
        var pushState = window.history.pushState;
        window.history.pushState = function (state) {
            this.setState({
                path: window.location.pathname,
            });
            return pushState.apply(window.history, arguments);
        }.bind(this);

        if (AppManager.isPublicURL()) {
            this.setState({
                didInitialApiResponseReceived: true,
            });
        } else {
            this.callInitialApis();
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    callInitialApis() {
        showLoader();
        ApiManager.checkIfUserLoggedIn(
            () => {
                const userDetails = UserManager.getUserDetails();
                UserService.setUserNameAndEmail(UserManager.getUserIamID());

                InitialApiManager.getPeopleSubscription().then((subscription) => {
                    /***Check if subscribed and then do not call trail API again */
                    if (subscription && !subscription.Subscribed && !subscription.TrialOver && (!userDetails.emailVerified || !userDetails.isOrgSet)) {
                        InitialApiManager.SubscribeTrailPlan();
                    }
                });

                InitialApiManager.getTableDataInit().then(
                    (data) => {
                        Tenant.setRolesDataForExpense();
                        Tenant.getTenantDetails().then((tenant) => {
                            TenantService.getUserData().then(
                                () => {
                                    TenantManager.setTenantDeatils(tenant);
                                    Table.getTenantData().then((peopleTenant) => {
                                        const countryCode = peopleTenant["tenant"]["countryCode"] || peopleTenant["tenant"]["address"]["country"] || tenant.country;
                                        const isAdmin = TenantService.get(TENANT.IS_ADMIN);
                                        this.redirectTo(isAdmin, countryCode, peopleTenant["tenant"]["orgSetupCompleted"]);

                                        if (countryCode === COUNTRY.US) {
                                            PaypalService.checkPaypalConnected();
                                        }

                                        InitialApiManager.getSubscription().then(
                                            (res) => {
                                                if (res?.ERPPlan?.Name || res?.AIOPlan?.Name) {
                                                    TenantService.set(TENANT.ERP_INTEGATION_SETTING, isAdmin && UserManager.hasBooksAccess());
                                                    LeftMenuUpdate.sendMessage(true);
                                                }
                                            }, (error) => { }
                                        );

                                        setTimeout(() => {
                                            if (!peopleTenant["tenant"]["orgSetupCompleted"] && !Utility.isEmpty(tenant.country)) {
                                                this.setupTenantCountry(isAdmin, peopleTenant["tenant"], tenant.country);
                                            } else {
                                                this.setState({
                                                    isAdmin: isAdmin,
                                                    activeRoutes: this.getActiveRoutes(isAdmin, countryCode),
                                                    didInitialApiResponseReceived: true,
                                                    isOrgSetupCompleted: peopleTenant["tenant"]["orgSetupCompleted"]
                                                });
                                            }

                                            DomainManager.getAllCustomDomainsFromAPI();
                                            removeLoader();
                                        }, 100);
                                    });
                                }, (error) => {
                                    switch (error.status) {
                                        case "UNAUTHENTICATED":
                                            this.showUnauthorizedPopup();
                                            break;
                                        case "FAILED":
                                            if (!Utility.isEmpty(error.errors) && error.errors[0]?.code === "UM_1003") {
                                                this.showUnauthorizedPopup();
                                            }
                                        default:
                                            showAlert("Problem While Loading!", "Unable to load view information. Please try again!");
                                            break;
                                    }
                                }
                            );
                        });
                    },
                    (err) => {
                        showAlert(
                            "Problem While Loading!",
                            "Unable to load view information. Please try again!"
                        );
                        removeLoader();
                    }
                );
            },
            () => { }
        );
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    initialAPIReceived = (err) => {
        if (UserManager.getUserDetails() && !UserManager.getUserDetails().emailVerified) {
            this.showEmailVerificationPopup();
        }

        this.setState({
            isOrgAvailable: true,
            didInitialApiResponseReceived: true,
        });

        removeLoader();
        MixPanelManager.init();
        MixPanelManager.openGO();
    };

    initialAPIFailed = (err = null) => {
        removeLoader();
        if (err && err.errorMessage === "Org not set") {
            this.setState({
                isOrgAvailable: false,
            });
        } else {
            showAlert("Error!", "Server error occurred.");
        }
    };

    getActiveRoutes(isAdmin, countryCode) {
        let isContractor = TenantService.get(TENANT.IS_CONTRACTOR);
        return ROUTES.filter((r) => {
            return (r.country.includes("-1") || r.country.includes(countryCode)) && ((r.accessTo === ADMIN_EMPLOYEE && !isContractor) || (isAdmin ? r.accessTo === ADMIN : isContractor ? r.accessTo === CONTRACTOR : r.accessTo === EMPLOYEE));
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    render() {
        if (window.location.hash.startsWith("#/")) {
            window.location = window.location.hash.replace("#", "");
        }

        return (
            <>
                <Router>
                    <div
                        className="screen-height bg-gray1 row align-items-start app-font "
                        style={{ position: "fixed" }}
                    >
                        {/* LEFT MENU */}
                        <Switch>
                            {/* <Route exact path={PAGE_ROUTES.PUBLIC_PAGE} component={Empty} /> */}
                            {this.state.didInitialApiResponseReceived && (
                                <Route
                                    path="/"
                                    render={(props) => (
                                        <LeftMenu
                                            accessedBy={this.state.isAdmin ? ADMIN : EMPLOYEE}
                                            {...props}
                                        />
                                    )}
                                />
                            )}
                        </Switch>
                        {/* END - LEFT MENU */}

                        <div
                            className=" parent-height column parent-width "
                            style={{ flex: 1, backgroundColor: "rgb(235, 239, 243)" }}
                        >
                            {/* TOP MENU */}
                            <Switch>
                                {/* <Route exact path={PAGE_ROUTES.PUBLIC_PAGE} component={Empty} /> */}
                                {this.state.didInitialApiResponseReceived && (
                                    <Route
                                        path="/"
                                        render={(props) => (
                                            <TopMenu
                                                accessedBy={this.state.isAdmin ? ADMIN : EMPLOYEE}
                                                {...props}
                                                isOrgSetupCompleted={this.state.isOrgSetupCompleted}
                                            />
                                        )}
                                    />
                                )}
                            </Switch>
                            {/* END - TOP MENU */}

                            {this.state.didInitialApiResponseReceived && (
                                <div
                                    id="containerDiv"
                                    className={
                                        "parent-width column " +
                                        (AppManager.isPublicURL() ? "" : " main-holder-padding ")
                                    }
                                    style={{ overflowY: "auto", flexGrow: 1 }}
                                >
                                    <Switch>
                                        {this.state.activeRoutes.map((r) => {
                                            return (
                                                <Route
                                                    exact
                                                    path={r.route}
                                                    render={(props) =>
                                                        r.render({
                                                            accessedBy: this.state.isAdmin ? ADMIN : EMPLOYEE,
                                                            ...props,
                                                        })
                                                    }
                                                />
                                            );
                                        })}

                                        <Route
                                            path="*"
                                            render={(props) => {
                                                RouteManager.setPresenter({ props });
                                                RouteManager.navigateToHome();
                                            }}
                                        />
                                    </Switch>
                                </div>
                            )}
                        </div>
                    </div>
                </Router>
            </>
        );
    }

    getLogger() {
        return (
            <div
                className="position-absolute border-radius-m"
                style={{ bottom: 20, right: 20, backgroundColor: "black" }}
            >
                <DKButton
                    title="Logger"
                    className="fw-m text-white"
                    onClick={() => {
                        this.setState({ needLogger: true });
                    }}
                />
            </div>
        );
    }

    showEmailVerificationPopup = () => {
        showAlert(
            `Verify your email address`,
            `We have sent an email to <b>${UserManager.getUserEmail()}</b>, If you don't see it, please check your spam folder`,
            [
                {
                    title: "Do it later",
                    className: "bg-gray1  border-m mt-r",
                },
                {
                    title: "Resend email",
                    onClick: () => this.resendVerificationEmail(),
                    className: "bg-app  text-white ml-r mt-r",
                },
            ]
        );
    };

    resendVerificationEmail = () => {
        IAM.resendVerificationEmail().then(
            (data) => { },
            (err) => { }
        );
    };

    redirectTo(isAdmin, countryCode, orgSetupCompleted) {
        if (ApiConstants.EMPLOYEE_LOGIN_REDIRECT && !isAdmin) {
            window.location.replace(ApiConstants.PRODUCT_URL_PEOPLE_PLUS);
            return;
        }

        if (!orgSetupCompleted) {
            window.location.replace(ApiConstants.PRODUCT_URL_PEOPLE_PLUS);
            return;
        }
    }

    getSubscription(trial) {
        if (trial) {
            showAlert(
                `<div class="fs-l">Start Trial</div>`,
                `<div class="fw-m fs-l mb-s">You do not have any active plan!</div>
                <div>Click below to start trial and continue using the product.</div>`,
                [
                    {
                        title: "Ok",
                        className: "bg-app text-white",
                        onClick: () => {
                            window.location.replace(ApiConstants.PRODUCT_URL_GO + "billing");
                        },
                    },
                ]
            );
        } else {
            showAlert(
                `<div class="fs-l">Trial Expired</div>`,
                `<div class="fw-m fs-l mb-s">Your trial period has expired!</div>
                <div>Click below to purchase a plan and continue using the product.</div>`,
                [
                    {
                        title: "Ok",
                        className: "bg-app text-white",
                        onClick: () => {
                            window.location.replace(ApiConstants.PRODUCT_URL_GO + "billing");
                        },
                    },
                ]
            );
        }
    }

    showUnauthorizedPopup() {
        showAlert(
            "Not Authorized",
            "You are not authorized to access this application. Please contact your organisation Admin.",
            [
                {
                    title: "Ok",
                    className: "bg-app text-white",
                    onClick: () => {
                        window.location.replace(ApiConstants.PRODUCT_URL_GO);
                    }
                }
            ]
        );
    }

    verifyEmail(userDetails) {
        const URL = ApiConstants.AUTH_BASE_URL + ApiConstants.URL.AUTH.VERIFY_EMAIL + `?mode=EMAILNOTVERIFIED&email=${userDetails.email}&name=&verified=false`;
        window.open(URL, "_self");
    }

    setupTenantCountry(isAdmin, tenant, country) {
        const countryObj = Countries.find((c) => c.value === country);
        const params = CompanyDetailService.getTenantSetupParams(deepClone(tenant), countryObj);

        CompanyDetailService.updateCompanyDetail(params).then(
            (response) => {
                if (response && response.status === "OK") {
                    TenantManager.setPeopleTenantData(response);

                    this.setState({
                        isAdmin: isAdmin,
                        activeRoutes: this.getActiveRoutes(isAdmin, country),
                        didInitialApiResponseReceived: true,
                        isOrgSetupCompleted: response["tenant"]["orgSetupCompleted"],
                    });

                    TenantService.set(TENANT.COUNTRY_CODE, countryObj?.value);
                    TenantService.set(TENANT.CURRENCY_CODE, countryObj?.currencyCode);
                }
            },
            (error) => {
                this.setState({
                    isAdmin: isAdmin,
                    activeRoutes: this.getActiveRoutes(isAdmin, "-1"),
                    didInitialApiResponseReceived: true,
                    isOrgSetupCompleted: tenant["tenant"]["orgSetupCompleted"],
                });
            }
        );
    }
}

export default App;
