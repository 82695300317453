import React, { Component } from "react";
import {
    DKLabel,
    TOAST_TYPE,
    DKButton,
    DKInput,
    showToast,
    showLoader,
    removeLoader
} from "deskera-ui-library";
import { COLUMN_CODE, TableManger, TABLES, TABLE_DISPLAY_NAME } from "../../managers/TableManager";
import { deepClone } from "../../utils/Utility";
import DataGridHolderPayroll from "../common/DataGridHolderPayroll";

import { insertRowIntoMultiClaim, parseColumnConfig, parseVisibleColumnConfig } from "../../services/Config";
import ExpenseService from "../../services/ExpenseService";
import Utility from "../../utils/Utility";
import { COMPANY_DATE_FORMATS, INPUT_TYPE } from "../../utils/Constants";
import { TENANT, TenantService } from "../../services/TenantMangerService";
import moment from "moment";
import { EXPENSE_CONSTANTS } from "../../services/ExpenseClaimListService";
import i18next from "i18next";

export default class MultipleExpensePopup extends Component {
    gridContainer = React.createRef();
    taxList = []
    categoryList = []
    selection = []
    dateFormat = ''
    expenseGroupRequire = false
    constructor(props) {
        super(props);
        this.state = {
            gridActions:{
                filters:[],
                currentPage:1,
                totalPageCount:1
            },
            gridData: {
                columnData: parseVisibleColumnConfig(parseColumnConfig(TableManger.getTableVisibleColumns(TABLES.PEOPLE_MULTIPLE_EXPENSE), TABLES.PEOPLE_MULTIPLE_EXPENSE)),
                rowData: [],
                filter: [],
                originalData: []
            },
            gridWidth:950,
            expneseGroupName:'',
            canValidate: false
        }
        this.getTaxes()
        this.getCategory()
        this.onRowAdd()

        let dateFormat = COMPANY_DATE_FORMATS.filter(c=>c.label==TenantService.get(TENANT.DATE_FORMAT))
        this.dateFormat = dateFormat[0]?.value
    }
    componentDidMount() {
        this.setState({
            gridWidth: (this.gridContainer?.current?.clientWidth || 950)
        })
    }
    getCategory() {
        ExpenseService.getCategory(false).then(
            (response) => {
                if (response) {
                    this.categoryList = response;
                    this.setDropDownOptionsToGrid("categoryId",response)
                }
            }, (error) => {
                if (error?.message === i18next.t("USER_IS_NOT_IN_GROUP")) {
                    showToast(error.message, TOAST_TYPE.FAILURE);
                }
            }
        )
    }
    getTaxes() {
        ExpenseService.getTaxes().then((res) => {
            if (res && res.content) {
                this.taxList = res.content;
                this.setDropDownOptionsToGrid("taxCode",res.content)
            }
        },
            error => {
                console.log(error)
            })
    }
    setDropDownOptionsToGrid(columnCode,content) {
        let columnData = deepClone(this.state.gridData.columnData)
        let ind = columnData.findIndex(field => field.key === columnCode);
        if (content.length > 0 && ind != -1) {
            columnData[ind].options = content.map(r => {
                r['color'] = ''
                return r
            });
        } else {
            columnData[ind].options = [];
        }

        this.setState({
            gridData: {
                ...this.state.gridData,
                columnData: columnData
            }
        })
    }
    onRowUpdate(data) {
        let fieldIndex = data.rowData.invalidFields.findIndex(f=>f==data.columnKey);
        if(data.columnData.required && Utility.isEmpty(data.rowData[data.columnKey])) {
            if(fieldIndex == -1) {
                data.rowData.invalidFields.push(data.columnKey)
            }
        } else if(fieldIndex != -1) {
            data.rowData.invalidFields.splice(fieldIndex,1)
        }
        switch(data.columnKey){
            case "taxCode":
                if(data.rowData['taxCode'] && data.rowData['taxCode'][0]) {
                    let i = this.taxList.findIndex(t=> t.id==data.rowData['taxCode'][0]);
                    let percent = 0
                    if(i!=-1) {
                        percent = this.taxList[i].percent
                    }
                    data.rowData['taxAmount'] = data.rowData['totalAmount']? ((data.rowData['totalAmount'] * percent) / 100) :0 ;
                } else {
                    data.rowData['taxAmount'] = 0
                }

                break;
            case "totalAmount":
                if(data.rowData['taxCode'] && !Utility.isEmpty(data.rowData['taxCode'][0])) {
                    let taxInd = this.taxList.findIndex(taxes=>taxes.id == data.rowData['taxCode'][0]);
                    if( taxInd != -1 && this.taxList[taxInd]?.percent) {
                        data.rowData['taxAmount'] = ((parseFloat(data.rowData['totalAmount'] )* this.taxList[taxInd].percent) / 100)  ;
                    }
                } else {
                    data.rowData['taxAmount'] = 0
                }
                break;
        }
    }
    onClose(isSave) {
        if(this.props.onClose) {
            this.props.onClose(isSave)
        }
    }
    checkIsValidGridData() {

        let cols = this.state.gridData.columnData.filter(r=>r.required);
        let isValid = true;
        if(this.expenseGroupRequire && Utility.isEmpty(this.state.expneseGroupName.trim())) {
            isValid = false;
        }
        cols.forEach(col=>{
            if(col.required) {
                let rows = deepClone(this.state.gridData.rowData);
                rows.forEach(row=>{
                    let fieldIndex = row.invalidFields.findIndex(f=>f==col.columnCode);
                    if(typeof row[col.columnCode] == 'string' && (Utility.isEmpty(row[col.columnCode]) || Utility.isEmpty(row[col.columnCode].trim()))) {
                        isValid = false
                        if(fieldIndex == -1) {
                            row['invalidFields'].push(col.columnCode)
                        }
                    } else if(Utility.isEmpty(row[col.columnCode]) && (row[col.columnCode]!=0) && col.type == INPUT_TYPE.NUMBER) {
                        isValid = false
                        if(fieldIndex == -1) {
                            row['invalidFields'].push(col.columnCode)
                        }
                    }
                    else if(Utility.isEmpty(row[col.columnCode]) && col.type !== INPUT_TYPE.NUMBER) {
                        isValid = false
                        if(fieldIndex == -1) {
                            row['invalidFields'].push(col.columnCode)
                        }
                    }
                    if(isValid) {
                        row['invalidFields'] = []
                    }
                })
                this.setState({
                    gridData:{
                        ...this.state.gridData,
                        rowData:rows
                    }
                })

            }
        })
        return isValid;
    }
    prepareJsonObjectToSave(status) {
        let finalPayload = {
            expneseGroupName: this.state.expneseGroupName.trim(),
            expneses:[]
        }
        let expenseObject = {
            attachments: [],
            currency: TenantService.get(TENANT.CURRENCY_CODE),
            id: null,
            attachmentIds: [],
            deletedAttachmentIds: [],
            status:status
        };
        this.state.gridData.rowData.forEach(row=>{
            let taxCode = null;
            if(row['taxCode'] && row['taxCode'].length !=0) {
                 let i = this.taxList.findIndex(t=> t.id==row['taxCode'][0]);
                 if(i!=-1) {
                    taxCode = this.taxList[i].code
                 }
            }
            if(row['attachments'] && !Utility.isEmpty(row['attachments'])) {
                let attachmentData = Utility.decodeJSON(row['attachments']);
                let ids = attachmentData.map(a=> a.attachmentId);
                expenseObject['attachments'] = ids;
                expenseObject['attachmentIds'] = ids;

            }
            let obj = {
                ...expenseObject,
                ...row,
                attachments: expenseObject['attachments'],
                spendDate: moment(row['spendDate']).format('YYYY-MM-DD'),
                categoryId: (row['categoryId'] && row['categoryId'].length !=0) ? row['categoryId'][0]:null,
                taxCode: (row['taxCode'] && row['taxCode'].length !=0) ? taxCode:null
            }
            finalPayload.expneses.push(obj)
        })

        return finalPayload
    }

    onSave(status) {
        setTimeout(() => {
            if(!this.checkIsValidGridData()) {
                this.setState({
                    canValidate:true
                })
                showToast(i18next.t("PLEASE_FILL_FILED"),TOAST_TYPE.WARNING);
                return
            }
            if(this.state.gridData?.rowData?.length == 0) {
                showToast(i18next.t("ADD_EXPENSE_DETAIL"), TOAST_TYPE.WARNING)
                return
            }
            showLoader()
            ExpenseService.saveMultiExpense(this.prepareJsonObjectToSave(status)).then(
                res=>{
                    showToast(i18next.t("GROUP_ADDED"), TOAST_TYPE.SUCCESS)
                    removeLoader();
                    this.onClose(true)
                },
                error=>{
                    removeLoader();
                    showToast(i18next.t("SOMETHING_WENT_WRONG"),TOAST_TYPE.FAILURE)
                }
            );

        }, 100);
    }
    onRowAdd() {
        let row = insertRowIntoMultiClaim(this.state.gridData.rowData)
        this.setState({
            gridData:{
                ...this.state.gridData,
                rowData:row
            }
        })
    }
    onRowDelete(data) {
        let row = deepClone(this.state.gridData.rowData)
        row.splice(data.rowIndex,1)
        this.setState({
            gridData:{
                ...this.state.gridData,
                rowData:row
            }
        })
    }
    onFileUpload(data) {
        if(data) {
            let rowData = deepClone(this.state.gridData.rowData[data.rowIndex])
            let attachment = rowData[COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.ATTACHMENTS]
                ? Utility.decodeJSON(rowData[COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.ATTACHMENTS])
                : [];
            attachment.push(data.file);
            rowData[COLUMN_CODE.PEOPLE_MULTIPLE_EXPENSE.ATTACHMENTS] = Utility.encodeJSON(attachment);
            let rowEdited = deepClone(this.state.gridData.rowData)
            rowEdited[data.rowIndex] = rowData
            this.setState({
                gridData:{
                    ...this.state.gridData,
                    rowData:rowEdited
                }
            })
        } else {
            this.setState({
                gridData:{
                    ...this.state.gridData
                }
            })
        }

    }
    renderTop() {
        return (
            <>
                <div className="row justify-content-between">
                <div className="web-width-30 web-width-30 d-flex align-items-center">
                <DKLabel className="web-width-50" text={`${i18next.t("GROUP_NAME_TITLE")}${this.expenseGroupRequire ? "*":""}`}/>
                <DKInput
                    required={this.expenseGroupRequire}
                    value={this.state.expneseGroupName}
                    canValidate={this.state.canValidate}
                    onChange={(value) => {
                        this.setState({
                            expneseGroupName:value
                        })
                    }}
                />
                </div>
                    <div className="d-flex">
                        <DKButton className="border-m bg-gray1" title="Cancel" onClick={() => this.onClose()} />
                        <DKButton className="border-m bg-gray1 text-app ml-m" title="+ Add Row" onClick={() => this.onRowAdd()} />
                        <DKButton className="border-m bg-gray1 text-app ml-m" title="Save" onClick={() => this.onSave(EXPENSE_CONSTANTS.DRAFT)} />
                        <DKButton className="bg-app text-white ml-m" title="Submit" onClick={() => this.onSave(EXPENSE_CONSTANTS.PENDING)} />
                    </div>
                </div>

            </>
        )
    }
    expenseGrid() {
        return (
            <div ref={this.gridContainer} className="mt-l">
            <DataGridHolderPayroll
                tableName={TABLES.PEOPLE_MULTIPLE_EXPENSE}
                displayTableName={TABLE_DISPLAY_NAME[TABLES.PEOPLE_MULTIPLE_EXPENSE]}
                allowColumnEdit={false}
                allowColumnDelete={false}
                allowColumnShift={false}
                allowSearch={false}
                allowFilter={false}
                allowColumnAdd={false}
                allowRowAdd={false}
                allowColumnSort={false}
                refresh={false}
                allowDataExport={false}
                allowRowEdit={true}
                showContextEdit={false}
                isActionEnabled={false}
                contextMenu={true}
                isMenuVisible={true}
                showContextMenu={true}
                gridData={this.state.gridData}
                filter={this.state.gridActions.filters}
                currentPage={this.state.gridActions.currentPage}
                totalPageCount={this.state.gridActions.totalPageCount}
                gridWidth={this.state.gridWidth}
                onRowOpenBtnHidden={true}
                onFilter={(dataList, queries) => {

                }}
                onRowUpdate={(data)=>{
                    this.onRowUpdate(data)
                }}
                onRowDelete={(data)=>{
                    this.onRowDelete(data)
                }}
                onPaginationClick={(requestedPageNumber) => {

                }}
                onSelection={(selection) => {
                    this.selection = (selection);
                }}
                uploadFile={(data)=>{
                    this.onFileUpload(data)
                }}
                allowBulkOperation={false}
                dateFormat={this.dateFormat}
            />
            </div>
        )
    }
    render() {
        return (
            <div className="transparent-background parent-height">
                <div className="popup-window" style={{ maxWidth: this.props.maxWidth, minHeight:'40vh', overflowY:'auto' }}>
                    <div className="column parent-width">
                        <div className="parent-width">
                            {this.renderTop()}
                            {this.expenseGrid()}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}