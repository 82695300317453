import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router";
import {
    DKIcon,
    DKLine,
    DKLabel,
    DKButton
  } from "deskera-ui-library";
import { getQueryParam } from "../../managers/common/CommonUtil";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import PaypalService from "../../services/PaypalService";
import paypal_logo from "../../assets/images/paypal_logo.png";
import connect_with_paypal from "../../assets/images/connect_with_paypal.png";
import ic_close from "../../assets/icons/ic_close.png";
import { TENANT, TenantService } from "../../services/TenantMangerService";
import { LeftMenuUpdate } from "../../services/LeftMenuService";

const PAYPAL_CONSTANTS = {
    TEXT_1: "In this step you need to connect your PayPal account, you will be redirected to the PayPal login page after you click on connect. Please use your existing PayPal account or signup for new account before starting this process.",
    TEXT_2: "<br><br>Your PayPal account is connected.<br><br>"
}

function PaypalConnect(props) {
    const history = useHistory();

    useEffect(() => {
        RouteManager.setPresenter({ props: { ...props, history: history } });

        const code = getQueryParam(props.location, "code");
        const scope = getQueryParam(props.location, "scope");
        const state = getQueryParam(props.location, "state");

        if (scope) {
            const params = `?code=${code}&scope=${scope}&state=${state}`;
            PaypalService.connectByScope(params).then(
                (response) => {
                    TenantService.set(TENANT.IS_PAYPAL_CONNECTED, true);
                    RouteManager.navigateToPage(PAGE_ROUTES.SETTINGS);
                    LeftMenuUpdate.sendMessage(true);
                }, (error) => {
                    TenantService.set(TENANT.IS_PAYPAL_CONNECTED, false);
                    RouteManager.navigateToHome();
                    LeftMenuUpdate.sendMessage(true);
                }
            );
        }
    }, []);

    const connectPaypal = () => {
        PaypalService.connectPaypal().then(
            (data) => {
                window.open(data.url, '_self');
            }
        );
    }

    const disconnectPaypal = () => {
        PaypalService.disconnectPaypal().then(
            () => {
                props.onDisconnect();
                LeftMenuUpdate.sendMessage(true);
            }, () => {
                LeftMenuUpdate.sendMessage(true);
            }
        );
    }

    return (
        <Fragment>
            {props.showPaypalConnect && <div className="transparent-background">
                <div className="popup-window p-0" style={{
                    minHeight: "200px",
                    height: "200px",
                    maxHeight: "200px",
                    minWidth: "450px",
                    width: "450px",
                    maxWidth: "450px"
                }}>
                    <div className="parent-height parent-width">
                        <div className="row align-items-start p-h-l pt-l pb-m">
                            <DKIcon src={paypal_logo} />
                            <DKIcon src={ic_close} className="ic-s ml-auto cursor-pointer" onClick={() => { props.onClose() }} />
                        </div>
                        <DKLine className="bg-gray3" />
                        <div className="p-h-l p-v-m">
                            <DKLabel text={!TenantService.get(TENANT.IS_PAYPAL_CONNECTED) ? PAYPAL_CONSTANTS.TEXT_1 : PAYPAL_CONSTANTS.TEXT_2} style={{ textAlign: "justify" }} />
                        </div>
                        <div className="row p-h-l p-v-s">
                            {!TenantService.get(TENANT.IS_PAYPAL_CONNECTED) &&
                                <DKIcon
                                    src={connect_with_paypal}
                                    className="ic-m cursor-pointer ml-auto"
                                    onClick={() => {
                                        connectPaypal();
                                    }}
                                />}
                            {TenantService.get(TENANT.IS_PAYPAL_CONNECTED) && 
                                <DKButton
                                    className="bg-red text-white ml-auto"
                                    title="Disconnect"
                                    onClick={() => {
                                        disconnectPaypal();  
                                    }}
                              />
                            }
                        </div>
                    </div>
                </div>
            </div>}
        </Fragment>
    );
}

export default PaypalConnect;
