// import { NotificationsUpdate } from "../services/NotificationService";
import AppManager from "./AppManager";
// import PlanManager from "./PlanManager";
// import AddOnManager from "./AddOnManager";
// import IntercomManager from "./IntercomManager";

export const PAGE_ROUTES = {
  DASHBOARD: "/",
  NEW_CLAIM: "/new-claim",
  SETTINGS: "/settings",
  EXPENSE: {
    EXPENSE_CLAIM: "/expense-claims",
    EXPENSE_INTEGARTION_SETTINGS: "/expense/integration-settings",
    EXPENSE_ADMIN_SETTING: "/expense-admin-setting",
    EXPENSE_GROUP_USER: "/expense-group-user",
  },
  PAYPAL_CONNECT: "/connect/paypal",
  USER_MANAGEMENT: "/user-management",
  PAYOUT_LOGS: "/payout-logs"
};
export default class RouteManager {
  static presenter = null;
  static setPresenter(presenter) {
    this.presenter = presenter;
    // NotificationsUpdate.sendMessage("Route change");
  }
  static navigateToPage(pageRoute, param = null) {
    RouteManager.presenter.props.history.push(
      pageRoute + (param ? "?" + param : "")
    );
    AppManager.scrollToTop();
  }

  static navigateToHome() {
    RouteManager.navigateToPage(PAGE_ROUTES.DASHBOARD);
  }
}
